<template>
  <div>
    <h4>{{ $t('Forward to 2nd level support') }}</h4>
    <v-alert
        text
        :type="messageType"
        v-if="message"
    >
      {{ message }}
    </v-alert>
    <form @submit.prevent="sendMail">
      <p>{{ $t('We accept only messages in english or german language!') }}</p>
      <v-textarea
          solo
          v-model="mail.content"
          background-color="white"
      />
      <p>{{ $t('Provide as many issue informations as possible to your colleagues') }}</p>
      <v-btn type="submit"
             class="btn subm-btn"
      >
        {{ $t("Send") }}
      </v-btn>
    </form>
  </div>
</template>

<script>
import SearchService from "@/services/SearchService";
import {KeycloakService} from "@/services/KeycloakService";

export default {
  name: "ForwardToSupport",
  props: ['subject', 'type', 'additional_information'],
  data: () => ({
    mail: {
      content: '',
      country: ''
    },
    message: '',
    messageType: '',
  }),
  methods:{
    sendMail() {
      this.mail.subject = this.subject
      this.mail.email_sender = KeycloakService.userName
      this.mail.type = this.type
      this.mail.url = window.location.href
      this.mail.additional_information = this.additional_information
      SearchService.sendMail(this.mail)
          .then(value => {
            this.successResponse(value.message)
          })
          .catch(reason => {
            this.errorResponse(reason.errors)
          })
    },
    errorResponse(errorMessage) {
      this.message = this.$i18n.t(errorMessage);
      this.messageType = 'error';
    },
    successResponse(successMessage) {
      this.message = this.$i18n.t(successMessage);
      this.messageType = 'success';
    }
  }
}
</script>

<style scoped>

</style>
