import Vue from 'vue'
import App from './App.vue'
// @ts-ignore
import vuetify from '@/plugins/vuetify' // path to vuetify export
// @ts-ignore
import router from '@/router'
import i18n from './i18n'
import axios from 'axios'
import {KeycloakService} from "./services/KeycloakService";
import {ConfigFileService} from "@/services/ConfigFileService";
import '@mdi/font/scss/materialdesignicons.scss'

function tokenInterceptor () {
  axios.interceptors.request.use(config => {
    // @ts-ignore
    config.headers.Authorization = `Bearer ${KeycloakService.token}`
    return config
  }, error => {
    return Promise.reject(error)
  })
}

export const fillUpWithZeros = (number:number) => {
  return number.toString().padStart(2, '0')
}



const kcLocale = i18n.locale
const initOptions = {
  'locale': kcLocale,
  'loginRequired': true
}

ConfigFileService.init()
    .then(async () => {
        KeycloakService.init(initOptions)
            .then(() => {
                tokenInterceptor()
                new Vue({
                    i18n,
                    router,
                    vuetify,
                    render: h => h(App),
                }).$mount('#app')
            })
            .catch()
    })
    .catch(reason => {
        console.log('Error while Start Application: ', reason);
    })
