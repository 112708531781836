<template>
    <div>
        <v-app-bar flat color="white">
            <v-container class="-max-width-limited">
                <v-toolbar-title>
                    <v-layout>
                        <v-flex shrink pa-2>
                            <a href="/">
                                <svg xmlns="http://www.w3.org/2000/svg" width="198" height="42" viewBox="0 0 198 42">
                                    <g fill="none" fill-rule="evenodd">
                                        <path class="logo__e--border" fill="#D50C1F" stroke="#FFF" stroke-width="2"
                                              d="M1 40.928h46.045V1H1z"/>
                                        <path class="logo__e" fill="#FFF"
                                              d="M21.464 23.96v-2h7.675v-3.99h7.67v-6.99H11.23v19.964h25.58V23.96"/>
                                        <path class="logo__text" fill="#000"
                                              d="M125.066 10.982v19.964h8.953v-8.984h3.832v8.984h8.954V17.97h-12.79v-6.99h-8.955zm-38.37 3.993h8.952v-3.993h-8.953v3.993zm-29.42-3.993v19.964H82.86V23.96H67.51v-2h7.674v-3.99h7.674v-6.99h-25.58zM189.02 30.946h8.953V10.982h-8.952v19.964zm-12.79 0h8.952V10.982h-8.953v19.964zM150.65 17.97v12.975h21.742v-3.993H159.6v-1.996h12.79V17.97h-21.743zm8.952 4.49h5.116v-1.996H159.6v1.996zm-60.115-4.49v12.975h8.954V21.96h3.84v8.985h8.953V17.97H99.485zm-12.79 12.975h8.953V17.97h-8.953v12.976z"/>
                                    </g>
                                </svg>
                            </a>
                        </v-flex>
                        <v-flex grow pa-2 class="text-uppercase headline mt-1">
                            {{ $t('main.title') }}
                        </v-flex>
                        <v-flex shrink pa-2 mr-3 class="text-right">
                            <LocalSwitcher class="mt-2"/>
                        </v-flex>
                        <v-flex shrink pa-2 class="text-right">
                            <v-avatar color="primary" size="42">
                                <span v-if="userInitials" class="white--text">{{ userInitials }}</span>
                                <v-icon v-else color="white">fas fa-user</v-icon>
                            </v-avatar>
                            <v-btn text
                                   @click="logout"
                            >
                                {{ $t('Logout') }}
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-toolbar-title>
            </v-container>
        </v-app-bar>
        <v-app-bar color="primary">
            <v-container class="-max-width-limited">
                <v-toolbar-title>
                    <v-btn class="font-weight-bold" color="primary" dark elevation="0" v-for="(item, index) in menu"
                           :key="index" :to="item.ref" v-show="checkRole(item.roles)">
                        {{ $t('menu.' + item.key) }}
                    </v-btn>
                </v-toolbar-title>
            </v-container>
        </v-app-bar>
    </div>
</template>
<script setup lang="ts">
import LocalSwitcher from "./LocalSwitcher";
import {KeycloakService, defaultRoles, warrantyReadRoles, warrantyEditRoles} from "@/services/KeycloakService";
import {onMounted, ref} from "vue";
import {ConfigFileService} from "@/services/ConfigFileService";

const userInitials = ref<string>('')
const menu = ref<{}[]>([
    {
        key: 'search',
        ref: "/search",
        roles: defaultRoles
    },
    {
        key: 'warranty_search',
        ref: '/warranty',
        roles: [...warrantyReadRoles,...warrantyEditRoles]
    },
    {
        key: 'warranty_user_create',
        ref: '/warranty-user/create',
        roles: warrantyEditRoles
    }
])

onMounted(() => {
    const firstName = KeycloakService.firstName;
    const lastName = KeycloakService.lastName;
    userInitials.value = firstName.charAt(0) + lastName.charAt(0);
})

const checkRole = (roles: string[]):boolean => {
    if (roles.length == 0 || roles.some(r => KeycloakService.roles.includes(r))) {
        return true
    }
    return false
}

const logout = () => {
    const id_token_hint = KeycloakService.idToken
    const redirect_uri = window.location.href
    const logoutUrl = ConfigFileService.keycloakUrl + "realms/" + ConfigFileService.keycloakRealm + "/protocol/openid-connect/logout"
        + "?post_logout_redirect_uri=" + encodeURIComponent(redirect_uri)
        + "&id_token_hint=" + id_token_hint
    window.location.href = logoutUrl
}
</script>

<style>
.-max-width-limited {
    max-width: 94% !important;
    margin-left: auto;
    margin-right: auto
}

@media (min-width: 40em) {
    .-max-width-limited {
        max-width: 90% !important
    }
}

@media (min-width: 64em) {
    .-max-width-limited {
        max-width: 86% !important
    }
}

@media (min-width: 90em) {
    .-max-width-limited {
        max-width: 82% !important
    }
}

@media (min-width: 105em) {
    .-max-width-limited {
        max-width: 1310px !important
    }
}

a {
    text-decoration: none;
}
</style>
