<template>
  <div>
    <section>
      <img src="@/assets/einhell-services-newsletter-keyvisual-desktop.jpg" width="100%" alt="Header Image"/>
    </section>
    <section class="container -max-width-limited">
      <v-row>
        <v-col>
          <div>
            <div class="box-left" style="margin-left: -350px; width: 852.649px; padding-left: 350px; background-color: white;">
              <div>
                <h1 class="hl">Einhell {{$t('main.title')}}</h1>
                <h2 class="sl gamma">{{$t('Display of orders, returns and user information')}}</h2>
                <p></p>
                <p></p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col>
          <div>
            <div>
              <span class="sl heading gamma">{{$t('How does it work?')}}</span>
              <p>{{$t('Order number, return number or e-mail address is used to search for the data')}}</p>
            </div>
            <div>
            </div>
          </div>
        </v-col>
      </v-row>
    </section>
    <v-snackbar
        v-model="snackbar"
        vertical
        right
        color="warning"
        :location="'top'"
    >
      <div class="text-subtitle-1 pb-2">{{ i18n.t(message) }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn
            color="black"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router/composables";
import i18n from "../i18n";

const router = useRouter()
const route = useRoute()

const message = ref<string>('')
const snackbar = ref<boolean>(false)

onMounted(() => {
  //@ts-ignore
  if (route.query.message) {
    //@ts-ignore
    displayMessage(route.query.message)
  }
  router.replace(router.currentRoute.path)
})

const displayMessage = (messageString:string) => {
  message.value = messageString
  snackbar.value = true
}
</script>

<style scoped>
section {
  font-size: .875em;
  text-align: left;
}

section h1 {
  color: #d50c1f;
}

.alpha, h1 {
  line-height: 1.2;
  font-size: 2.44141em;
}

.heading + p, h1 + p, h2 + p, h3 + p, h4 + p, h5 + p, h6 + p {
  margin-top: .5787em;
}

.paragraph, p {
  margin: 0 0 1em;
}

p {
  color: rgba(31, 33, 38, .6);
}

.box-left {
  border-radius: 0 3px 0 0;
  box-shadow: 110px 0 150px -100px rgba(0, 0, 0, .2);
  padding-right: 8.60731%;
  padding-top: 3.8147em;
  padding-bottom: 3.8147em;
  margin-top: -79px;
  z-index: 100;
}
</style>
