import {WarrantyService} from "@/services/WarrantyService";
import {store} from "@/store";
class InternalWarrantyLocationInfoService {
    getCountries () {
        WarrantyService.getCountries()
            .then(value => {
                // @ts-ignore
                store.warranty.availableCountries = value.sort()
            })
            .catch(reason => {
                console.error(reason)
            });
    }

    getLocales (country:string) {
        WarrantyService.getLocale(country.toLowerCase())
            .then(value => {
                // @ts-ignore
                store.warranty.availableLocales = value
            })
            .catch(reason => {
                console.error(reason)
            });
    }

    getDomains (country:string) {
        WarrantyService.getDomain(country.toLowerCase())
            .then(value => {
                if (Array.isArray(value)) {
                    // @ts-ignore
                    store.warranty.availableDomains = value
                } else {
                    store.warranty.availableDomains = [value]
                }
            })
            .catch(reason => {
                console.error(reason)
            });
    }
}

export const WarrantyLocationInfoService = new InternalWarrantyLocationInfoService()
