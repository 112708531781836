import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'mdiSvg', //'fa'
    },
    theme: {
        dark: false,
        themes: {
            dark: {
                primary: '#D50C1F',
                accent: '#FFFFFF',
                secondary: '#E43044',
                success: '#4CAF50',
                info: '#2196F3',
                warning: '#FB8C00',
                error: '#FF5252'
            },
            light: {
                primary: '#D50C1F',
                accent: '#FFFFFF',
                secondary: '#E43044',
                success: '#4CAF50',
                info: '#2196F3',
                warning: '#FB8C00',
                error: '#FF5252'
            }
        }
    }
}

export default new Vuetify(opts)
