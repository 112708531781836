<template>
  <v-container -max-width-limited>
      <v-skeleton-loader
              v-if="!keycloakUser"
              type="image"
      ></v-skeleton-loader>
    <v-alert
        text
        :type="messageType"
        v-if="message"
    >
      {{ message }}
    </v-alert>
    <v-card :color="'#eee'" class="mt-5 mb-5" v-if="keycloakUser">
      <v-container>
        <div class="col-md-12">
          <h2>{{ $t('MyEinhell Customer Informationen') }}</h2>
          <v-row>
            <v-col>
              <table>
                <caption></caption>
                <thead>
                <tr>
                  <th id="default"></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <label>{{ $t('Username') }}: </label>
                  </td>
                  <td class="value">
                    <strong>{{ keycloakUser.username }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{{ $t('E-Mail') }}: </label>
                  </td>
                  <td class="value">
                    <strong>{{ keycloakUser.email }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{{ $t('Name') }}: </label>
                  </td>
                  <td class="value">
                    <strong>{{ keycloakUser.firstName }} {{ keycloakUser.lastName }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{{ $t('Created on') }}: </label>
                  </td>
                  <td class="value">
                    <strong>{{ convertTimestamp(keycloakUser.createdTimestamp) }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{{ $t('Realm') }}: </label>
                  </td>
                  <td class="value">
                    <strong>{{ keycloakUser.realm }}</strong>
                  </td>
                </tr>
                </tbody>
              </table>
            </v-col>
            <v-col>
              <table>
                <caption></caption>
                <thead>
                <tr>
                  <th id="default"></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <label>{{ $t('Active') }}: </label>
                  </td>
                  <td class="value">
                    <strong>{{ $t(boolValue(keycloakUser.enabled)) }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{{ $t('E-mail verified') }}: </label>
                  </td>
                  <td class="value">
                    <strong>{{ $t(boolValue(keycloakUser.emailVerified)) }}
                    </strong>
                  </td>
                  <td v-if="!keycloakUser.emailVerified">
                    <v-btn @click="sendVerificationEmail(keycloakUser.id)">
                      {{ $t('Send verification email') }}
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>{{ $t('Multiple authentication') }}: </label>
                  </td>
                  <td class="value">
                    <strong>{{ $t(boolValue(keycloakUser.totp)) }}

                    </strong>
                  </td>
                  <td v-if="keycloakUser.totp">
                    <v-btn @click="removeTotp(keycloakUser.id)">
                      {{ $t('Remove Totp') }}
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label></label>
                  </td>
                  <td></td>
                  <td class="value">
                    <v-btn @click="resetPassword(keycloakUser.id)">
                      {{ $t('Reset Password') }}
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
          <div v-if="keycloakUser.session">
            <h4>{{ $t('Sessions') }}</h4>
            <v-row>
              <v-col v-for="session in keycloakUser.session" :key="session.id" class="login-information">
                <h5>{{ $t('Logged on') }}: </h5>
                <div>
                  <span v-for="client in session.clients" :key="client">
                    {{ $t('myeinhell.client.' + client) }}
                  </span>
                </div>
                <div class="session-information">
                  <table>
                    <caption></caption>
                    <thead>
                    <tr>
                      <th id="default"></th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr><td>{{ $t('Start of session') }}:</td><td>{{ convertTimestamp(session.start) }}</td></tr>
                      <tr><td>{{ $t('Last access') }}:</td><td>{{ convertTimestamp(session.lastAccess) }}</td></tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-row>
            <br>
          </div>
          <forward-to-support
              :type="'keycloak'"
              :subject="keycloakUser.email"
          />
        </div>
      </v-container>
    </v-card>
    <v-btn
        @click="$router.back()"
        class="back-btn"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t('Back') }}
    </v-btn>
  </v-container>
</template>

<script>
import {convertTimestamp} from "@/services/globalFunctions";
import SearchService from "@/services/SearchService";
import ForwardToSupport from "@/components/base/ForwardToSupport";

export default {
  name: "KeycloakUserDetail",
  components: {
    ForwardToSupport
  },
  data: () => ({
    keycloakUser: null,
    message: '',
    messageType: '',
    mail: {
      content: '',
      subject: '',
      type: 'keycloak',
      email_sender: ''
    }
  }),
  mounted() {
    const id = this.$route.params.id
    const realm = this.$route.params.realm
    SearchService.getMyEinhellUser(id, realm)
        .then(value => {
          this.keycloakUser = value
        })
        .catch(reason => {
          if (reason['errors'] !== undefined) {
            this.$router.push({
              name: 'search',
              query: { message: 'default_error_message' }
            })
          } else {
            console.log(reason)
          }
        });
  },
  methods: {
    convertTimestamp(timestamp) {
      return convertTimestamp(timestamp);
    },
    boolValue(value) {
      if (value) {
        return "Yes"
      }
      return "No"
    },
    sendVerificationEmail(id) {
      SearchService.sendVerifyEmail(id, this.keycloakUser.realm)
          .then(value => {
            this.successResponse(value.message)
          })
          .catch(reason => {
            this.errorResponse(reason.errors)
          })
    },
    removeTotp(id) {
      SearchService.removeTotp(id, this.keycloakUser.realm)
          .then(value => {
            this.successResponse(value.message)
            this.keycloakUser.totp = false
          })
          .catch(reason => {
            this.errorResponse(reason.errors)
          })
    },
    resetPassword(id) {
      SearchService.resetPassword(id, this.keycloakUser.realm)
          .then(value => {
            this.successResponse(value.message)
            this.keycloakUser.totp = false
          })
          .catch(reason => {
            this.errorResponse(reason.errors)
          })
    },
    errorResponse(errorMessage) {
      this.message = this.$i18n.t(errorMessage);
      this.messageType = 'error';
      this.snackbar = true
      this.$vuetify.goTo(0)
    },
    successResponse(errorMessage) {
      this.message = this.$i18n.t(errorMessage);
      this.messageType = 'success';
      this.snackbar = true
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>

</style>
