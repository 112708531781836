<template>
  <v-container -max-width-limited>
    <v-skeleton-loader
        v-if="orderList.length == 0 && !keycloakUser"
        type="image"
    ></v-skeleton-loader>
    <v-card :color="'#eee'" class="mt-5 mb-5" v-for="order in orderList" :key="order.id">
      <v-container>
        <div class="col-md-12">
          <strong>{{ $t('Order') }} #{{ order.number }} <small
              v-if="order.orderDate">({{ formatDate(order.orderDate) }})</small>
          </strong><span v-if="order.state"> | {{ $t('order.status.' + order.state) }}</span>
        </div>
        <div class="col-md-12">
        </div>
        <div class="col-md-12">
          <v-btn
              @click="showDetails(order.number)"
          >
            {{ $t('Show Details') }}
          </v-btn>
        </div>
      </v-container>
    </v-card>
    <v-btn
        @click="$router.back()"
        class="back-btn"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t('Back') }}
    </v-btn>
  </v-container>
</template>

<script>
import SearchService from "../../services/SearchService";
import {formatDate, formatMoney} from "@/services/globalFunctions";

export default {
  name: "OrderList",
  data: () => ({
    headers: [],
    orderList: [],
    keycloakUser: null
  }),
  mounted() {
    this.headers = [
      {text: this.$i18n.t('Articlenumber'), value: 'itemNo', sortable: false},
    ];
    const email = this.$route.query.email
    SearchService.getOrderByEmail(email)
        .then(value => {
          this.orderList = value.orders
        })
        .catch(reason => {
          if (reason['errors'] !== undefined) {
            this.$router.push({
              name: 'search',
              query: {message: reason.errors}
            })
          } else {
            console.log(reason)
          }
        });
  },
  methods: {
    showDetails(id) {
      this.$router.push({
        name: 'order_detail',
        params: {id: id}
      })
    },
    createItemList(order) {
      const items = []
      order.items.forEach(function (item) {
        items.push({
          itemNo: item
        })
      })
      return items
    },
    formatMoney(total, currencyCode) {
      return formatMoney(total, currencyCode);
    },
    formatDate(dateString) {
      return formatDate(dateString)
    }
  }
}
</script>

<style scoped>

</style>
