<template>
  <v-container -max-width-limited>
      <v-skeleton-loader
              v-if="roboServiceList.length === 0"
              type="image"
      ></v-skeleton-loader>
    <v-card :color="'#eee'" class="mt-5 mb-5" v-for="roboService in roboServiceList" :key="roboService.id">
      <v-container>
        <strong>{{roboService.orderIdentifier}}</strong>
        <p>{{$t('Current status')}}: {{$t('roboservice.status.'+ roboService.status)}} </p>
        <div>{{ roboService.orderContent.name }}</div>

        <v-btn
            @click="showDetails(roboService.id)"
        >
          {{$t('Show Details')}}
        </v-btn>
      </v-container>
    </v-card>
    <v-btn
        @click="$router.back()"
        class="back-btn"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{$t('Back')}}
    </v-btn>
  </v-container>
</template>

<script>
import SearchService from "@/services/SearchService";

export default {
  name: "RoboServiceList",
  data: () => ({
    roboServiceList: [],
  }),
  mounted() {
    const email = this.$route.query.email
    SearchService.getRoboServiceByEmail(email)
        .then(value => {
          this.roboServiceList = value.roboservice
        })
        .catch(reason => {
          if (reason['errors'] !== undefined) {
            this.$router.push({
              name: 'search',
              query: { message: reason.errors }
            })
          } else {
            console.log(reason)
          }
        });
  },
  methods: {
    showDetails (id) {
      this.$router.push({
        name: 'roboservice_detail',
        params: {id: id}
      })
    }
  }
}
</script>

<style scoped>

</style>
