// @ts-ignore
// eslint-disable-next-line no-unused-vars
import Keycloak from 'keycloak-js'
import {ConfigFileService} from "@/services/ConfigFileService";

type initOptions = {
  url: string,
  realm: string,
  clientId: string,
  onLoad: 'login-required'|'check-sso'
}

export const defaultRoles = ['default-roles-cic', 'EWMS_CIC_USER']
export const warrantyDeleteRoles = ['EWMS_CIC_WARRANTY_USER_DELETE']
export const warrantyEditRoles = ['EWMS_CIC_WARRANTY_USER_EDIT', ...warrantyDeleteRoles]
export const warrantyReadRoles = ['EWMS_CIC_WARRANTY_USER_READ', ...warrantyEditRoles]

class InternalKeycloakService {
  private _keycloak: Keycloak | null = null
  private _user: any | null = null
  private _locale: string | null = null
  private _loginRequired: string | null = null

  private _sessionTimeoutChecker: number | null = null

  async init (init:any) {
    this._locale = init.locale
    this._loginRequired = init.loginRequired

    // @ts-ignore
    const oauthDomain = ConfigFileService.keycloakUrl
    const keycloakRealm = ConfigFileService.keycloakRealm
    const clientId = ConfigFileService.keycloakClientId

    const initOptions: initOptions = {
      url: oauthDomain,
      realm: keycloakRealm,
      clientId: clientId,
      onLoad: 'check-sso'
    }

    const keycloak = Keycloak(initOptions)
    this._keycloak = keycloak

    // init Keycloak
    const authenticated = await keycloak.init({ onLoad: initOptions.onLoad, checkLoginIframe: false })

    if (authenticated) {
      const profile = await keycloak.loadUserProfile()
      const userinfo = await keycloak.loadUserInfo()

      if (profile && userinfo) {
        if (profile.username && profile.email) {
          this._user = {
            ...profile,
            ...userinfo
          }
          this.refreshToken()
        } else {
          // Todo: Add Error Handling
          throw Error('User Profile missing. Please Contact Admin')
        }
      }
    } else {
      if (this._loginRequired) {
        await this.login()
      }
    }
  }

  async login () {
    if (this._keycloak) {
      window.location.href = this._keycloak.createLoginUrl({ redirectUri: window.location.href }) + '&kc_locale=' + this._locale
    }
  }

  async logout () {
    if (this._keycloak) {
      return this._keycloak.logout()
    } else {
      throw new Error('No Keycloak Instance')
    }
  }

  async refreshToken (){
    if (this._keycloak) {
      if (this._keycloak.isTokenExpired(60)) {
        await this._keycloak.updateToken(60)
        if (this._sessionTimeoutChecker) {
          clearTimeout(this._sessionTimeoutChecker)
        }
        this.refreshToken()
      } else {
        this._sessionTimeoutChecker = window.setTimeout(() => {
          this.refreshToken()
        }, 30000)
      }
    } else {
      throw new Error('No Keycloak Instance')
    }
  }

  // Getters
  /**
   * Returns the token as string for the Authorization header.
   */
  get token (): string {
    if (this._keycloak) {
      if (this._keycloak.isTokenExpired(60)) {
        this._keycloak.updateToken(60)
      }
      return `${this._keycloak.token}`
    } else {
      throw new Error('No Keycloak Instance')
    }
  }

  get idToken () : string {
    if (this._keycloak) {
      return `${this._keycloak.idToken}`
    } else {
      throw new Error('No Keycloak Instance')
    }
  }

  get authenticated (): boolean {
    if (this._keycloak) {
      return this._keycloak.authenticated
        ? this._keycloak.authenticated
        : false
    } else {
      return false
    }
  }

  get userName (): string {
    if (this._keycloak) {
      return this._user
        ? this._user.username || ''
        : ''
    } else {
      return ''
    }
  }

  get fullName (): string {
    return this.firstName + ' ' + this.lastName
  }

  get firstName (): string {
    if (this._keycloak) {
      return this._user
        ? this._user.firstName || ''
        : ''
    } else {
      return ''
    }
  }

  get lastName (): string {
    if (this._keycloak) {
      return this._user
        ? this._user.lastName || ''
        : ''
    } else {
      return ''
    }
  }

  get subject (): string {
    if (this._keycloak) {
      return this._user.sub
        ? this._user.sub || ''
        : ''
    } else {
      return ''
    }
  }

  get roles (): string[] {
    if (this._keycloak) {
      if (this._keycloak.realmAccess && this._keycloak.realmAccess.roles){
        const roles = this._keycloak.realmAccess.roles
        const backendRoles = this.backendRoles
        return [...roles,...backendRoles]
      }
      return []
    } else {
      throw new Error('No Keycloak Instance')
    }
  }

  get backendRoles():string[] {
    const jsonPayload = this.parseJwt()

    return jsonPayload["resource_access"]["cic-backend"]["roles"]
  }

  parseJwt (): any {
    const base64Url = this.token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }
}

export const KeycloakService = new InternalKeycloakService()
