import axios from 'axios'
import {ConfigFileService} from "@/services/ConfigFileService";

export default {
    async getRetoureByEmail(email, roles) {
        const data = {
            "roles": roles
        }
        const res = await axios.post(`${ConfigFileService.apiUrl}cic/search/email/retoure`, data, {
            params: {
                email: email
            }
        })
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async getCustomerComplaintByEmail(email, roles) {
        const data = {
            "roles": roles
        }
        const res = await axios.post(`${ConfigFileService.apiUrl}cic/search/email/customercomplaint`, data, {
            params: {
                email: email
            }
        })
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async getOrderByEmail(email) {
        const res = await axios.get(`${ConfigFileService.apiUrl}cic/search/email/order`, {
            params: {
                email: email
            }
        })
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async getRoboServiceByEmail(email) {
        const res = await axios.get(`${ConfigFileService.apiUrl}cic/search/email/robo`, {
            params: {
                email: email
            }
        })
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async getMyEinhellUser(id, realm) {
        const res = await axios.get(`${ConfigFileService.apiUrl}cic/user/${realm}/${id}`)
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async getMyEinhellUsers(email) {
        const res = await axios.get(`${ConfigFileService.apiUrl}cic/user/`, {
            params: {
                email: email
            }
        })
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async resetPassword(userId, realm) {
        const res = await axios.get(`${ConfigFileService.apiUrl}cic/user/reset-password`, {
            params: {
                user_id: userId,
                realm: realm
            }
        })
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async sendVerifyEmail(userId, realm) {
        const res = await axios.get(`${ConfigFileService.apiUrl}cic/user/send-verify-email`, {
            params: {
                user_id: userId,
                realm: realm
            }
        })
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async removeTotp(userId, realm) {
        const res = await axios.get(`${ConfigFileService.apiUrl}cic/user/remove-totp`, {
            params: {
                user_id: userId,
                realm: realm
            }
        })
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async getRetoure(number) {
        const res = await axios.get(`${ConfigFileService.apiUrl}cic/retoure/${number}`)
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async getCustomerComplaint(number) {
        const res = await axios.get(`${ConfigFileService.apiUrl}cic/customercomplaint/${number}`)
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async getOrder(number) {
        const res = await axios.get(`${ConfigFileService.apiUrl}api/cic/order/${number}`)
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async getServiceOrder(id) {
        const res = await axios.get(`${ConfigFileService.apiUrl}cic/serviceOrder/${id}`)
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async quickSearch(number) {
        const res = await axios.get(`${ConfigFileService.apiUrl}cic/search/quick`, {
            params: {
                number: number
            }
        })
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async sendMail(data) {
        const res = await axios.post(`${ConfigFileService.apiUrl}cic/mail/send`, data)
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    },
    async sendRetoureLabel(id) {
        const res = await axios.put(`${ConfigFileService.apiUrl}cic/retoure/${id}/send-retoure-label`)
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    }
}
