import axios from "axios";

type Config = {
    VUE_APP_API_ENDPOINT_URL: string
    VUE_APP_WARRANTY_API_ENDPOINT_URL: string
    VUE_APP_OAUTH_URL: string
    VUE_APP_KEYCLOAK_REALM: string
    VUE_APP_KEYCLOAK_CLIENT_ID: string
}

class InternalConfigFileService {
    private _apiUrl: string | null = null
    private _keycloakUrl: string | null = null
    private _warrantyUrl: string | null = null
    private _keycloakRealm: string | null = null
    private _keycloakClientId: string | null = null

    async init(): Promise<void> {
        const applicationEnv = (await axios.get<Config>('settings.json')).data
        if (applicationEnv) {
            this._apiUrl = applicationEnv.VUE_APP_API_ENDPOINT_URL
            this._warrantyUrl = applicationEnv.VUE_APP_WARRANTY_API_ENDPOINT_URL
            this._keycloakUrl = applicationEnv.VUE_APP_OAUTH_URL
            this._keycloakRealm = applicationEnv.VUE_APP_KEYCLOAK_REALM
            this._keycloakClientId = applicationEnv.VUE_APP_KEYCLOAK_CLIENT_ID
        } else {
            throw new Error('No settings file: Could not init application')
        }

    }

    get apiUrl(): string {
        if (this._apiUrl) {
            return this._apiUrl
        } else {
            throw new Error('Api URL Error: Could not init application')
        }
    }

    get warrantyUrl(): string {
        if (this._warrantyUrl) {
            return this._warrantyUrl
        } else {
            throw new Error('Warranty URL Error: Could not init application')
        }
    }

    get keycloakUrl(): string {
        if (this._keycloakUrl) {
            return this._keycloakUrl
        } else {
            throw new Error('Keycloak URL Error: Could not init application')
        }
    }

    get keycloakRealm(): string {
        if (this._keycloakRealm) {
            return this._keycloakRealm
        } else {
            throw new Error('Keycloak Realm Error: Could not init application')
        }
    }

    get keycloakClientId(): string {
        if (this._keycloakClientId) {
            return this._keycloakClientId
        } else {
            throw new Error('Keycloak ClientId Error: Could not init application')
        }
    }
}

export const ConfigFileService = new InternalConfigFileService()
