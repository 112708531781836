// @ts-ignore
import type {WarrantySearchModelType} from "@/types/WarrantySearchModelType";
import axios from 'axios'
import {WarrantyUserType} from "@/types/WarrantyUserType";
import {WarrantyType} from "@/types/WarrantyType";
import {WarrantySaveType} from "@/types/WarrantySaveType";
import {ConfigFileService} from "@/services/ConfigFileService";

class InternalWarrantyService {
    async search(searchModel: WarrantySearchModelType): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/user"
        const res = await axios.get(url, {
            params: searchModel
        })
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    }

    async getUser(id: string): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/user/" + id
        const res = await axios.get(url)
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    }
    async updateUser(id: string, user: WarrantyUserType): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/user/" + id
        const res = await axios.put(url, user)
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    }
    async createUser(user: WarrantyUserType): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/user/"
        const res = await axios.post(url, user)
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    }
    async deleteUser(id: string): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/user/" + id
        const res = await axios.delete(url)
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    }
    async getUserWarranties(id: string, locale: string): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/user/" + id + "/warranties"
        const res = await axios.get(url, { params: { locale: locale }})
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    }
    async createWarranty(userId:string, warranty: WarrantyType): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/user/" + userId + "/warranties"
        const res = await axios.post(url, warranty)
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    }
    async updateWarranty(userId:string, warrantyId:string, locale:string, warranty: WarrantySaveType): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/user/" + userId + "/warranties/" + warrantyId
        const res = await axios.put(url, warranty, {params: {locale: locale}})
            .catch(function (error) {
                throw error.response.data
            })

        return res.data
    }
    async deleteWarranty(userId:string, warrantyId:string): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/user/" + userId + "/warranties/" + warrantyId
        const res = await axios.delete(url)
            .catch(function (error) {
                throw error.response.data
            })
        return res.data
    }
    async mergeWarranty(userId:string, newUserId:string): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/user/" + userId + "/merge/" + newUserId
        const res = await axios.post(url)
            .catch(function (error) {
                throw error.response.data
            })
        return res.data
    }
    async sendEmail(userId:string, warrantyId:string, locale:string): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/user/" + userId + "/warranties/" + warrantyId + "/sendEmail"
        const res = await axios.get(url, {params: {locale: locale}})
            .catch(function (error) {
                throw error.response.data
            })
        return res.data
    }
    async download(userId:string, warrantyId:string, locale:string): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/user/" + userId + "/warranties/" + warrantyId + "/download"
        const res = await axios.get(url, {
            params: {locale: locale},
            responseType: "blob"
        })
            .catch(function (error) {
                throw error.response.data
            })
        return res.data
    }
    async getCountries(): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/settings/countries/"
        const res = await axios.get(url)
            .catch(function (error) {
                throw error.response.data
            })
        return res.data
    }
    async getLocale(country:string): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/settings/locale"
        const res = await axios.get(url, {
            params: {country: country}
        })
            .catch(function (error) {
                throw error.response.data
            })
        return res.data
    }
    async getDomain(country:string): Promise<any> {
        const url = ConfigFileService.warrantyUrl + "api/admin/settings/domain"
        const res = await axios.get(url, {
            params: {country: country}
        })
            .catch(function (error) {
                throw error.response.data
            })
        return res.data
    }
}

export const WarrantyService = new InternalWarrantyService()
