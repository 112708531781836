<template>
  <v-container -max-width-limited>
      <v-skeleton-loader
              v-if="!roboService"
              type="image"
      ></v-skeleton-loader>
    <v-card :color="'#eee'" v-if="roboService" class="mt-5 mb-5">
      <v-container>
        <div class="col-md-12">
          <div class="servicecenter-page" id="servicecenter">
            <div class="bodytext clearfix">
              <h2>{{$t('Information about the service article')}}: {{ roboService.orderIdentifier }}</h2>
              <v-row>
                <v-col>
                  <h4>{{$t('Service Article Information')}}</h4>
                  <div class="headline">{{ roboService.orderContent.name }}</div>
                  <div>{{ roboService.orderContent.itemNumber }} {{ roboService.orderContent.identNumber }}</div>
                </v-col>
                <v-col v-if="roboService.serviceItem">
                  <v-img :src="loadItemImage(roboService.serviceItem)" max-width="150"/>
                </v-col>
              </v-row>
            </div>

            <div>
              <v-row>
                <v-col>
                  <h4>{{$t('Order Information')}}</h4>
                  <table>
                    <caption></caption>
                    <thead>
                      <tr>
                        <th id="default"></th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        <label>{{$t('Order number')}}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ roboService.orderContent.shopOrdernumber }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{$t('Order date')}}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ formatDate(roboService.orderContent.shopBillingDetails.orderDate) }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{$t('Amount')}}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ formatMoney(roboService.orderContent.shopBillingDetails.orderAmount) }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{$t('Payment method')}}: </label>
                      </td>
                      <td class="value">
                        <strong>{{
                            $t("order.payment.method." + roboService.orderContent.shopBillingDetails.paymentMethod)
                          }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{$t('Ordered in')}} </label>
                      </td>
                      <td class="value">
                        <strong>{{ roboService.orderContent.shopBillingDetails.orderSystem }}
                          ({{ roboService.orderContent.orderCountry }})</strong>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </v-col>
                <v-col>
                  <h4 class="icon-head head-billing-address">{{$t('Billing address')}}</h4>
                  <fieldset>
                    <legend></legend>
                    <address style="background-color:#fff">
                      {{ roboService.orderContent.shopBillingDetails.billingAddress.name }}<br>
                      {{ roboService.orderContent.shopBillingDetails.billingAddress.street }}
                      {{ roboService.orderContent.shopBillingDetails.billingAddress.streetNumber }}<br>
                      {{ roboService.orderContent.shopBillingDetails.billingAddress.postalCode }}
                      {{ roboService.orderContent.shopBillingDetails.billingAddress.city }}<br>
                      {{
                        $t('main.country.' + roboService.orderContent.shopBillingDetails.billingAddress.country.toLowerCase())
                      }}<br>
                    </address>
                  </fieldset>
                </v-col>
              </v-row>
            </div>

            <v-stepper v-model="el">
              <v-stepper-header>
                <v-stepper-step :complete="roboService.status >= 10" step="1">{{ $t('roboservice.status.10') }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="roboService.status >= 20" step="2">{{ $t('roboservice.status.20') }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="roboService.status >= 30" step="3">{{ $t('roboservice.status.30') }}
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>

            <div v-if="roboService.finishedContent && roboService.finishedContent.serviceOrders">
              <div v-for="(serviceOrder, index) in roboService.finishedContent.serviceOrders" :key="index">
                <v-row>
                  <v-col>
                    <h4>{{$t('User Information')}}</h4>
                    <table>
                      <caption></caption>
                      <thead>
                      <tr>
                        <th id="default"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td colspan="2">
                          {{ serviceOrder.serviceCustomer.customerFirstName }}
                          {{ serviceOrder.serviceCustomer.customerLastName }}
                        </td>
                      </tr>
                      <tr>
                        <td><label>{{$t('E-Mail')}}: </label></td>
                        <td>{{ serviceOrder.serviceCustomer.customerEmail }}</td>
                      </tr>
                      <tr v-if="serviceOrder.serviceCustomer.customerPhone">
                        <td><label>{{$t('Phone')}}: </label></td>
                        <td>{{ serviceOrder.serviceCustomer.customerPhone }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </v-col>
                  <v-col>
                    <h4>{{$t('Address for the service')}}</h4>
                    <fieldset>
                      <legend></legend>
                      <address style="background-color:#fff">
                        {{ serviceOrder.serviceLocation.street }} {{ serviceOrder.serviceLocation.streetNumber }}<br>
                        {{ serviceOrder.serviceLocation.postalCode }} {{ serviceOrder.serviceLocation.city }}<br>
                        {{ serviceOrder.serviceLocation.country }}<br>
                      </address>
                    </fieldset>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="serviceOrder.serviceDetails.garden">
                    <h4>{{$t('Garden Information')}}</h4>
                    <table>
                      <caption></caption>
                      <thead>
                      <tr>
                        <th id="default"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="serviceOrder.serviceDetails.garden.size">
                        <td><label style="white-space: nowrap">{{$t('Garden size in m²')}}: </label></td>
                        <td>{{serviceOrder.serviceDetails.garden.size}}</td>
                      </tr>
                      <tr v-if="serviceOrder.serviceDetails.garden.zones">
                        <td><label>{{$t('Zones')}}: </label></td>
                        <td>{{serviceOrder.serviceDetails.garden.zones}}</td>
                      </tr>
                      <tr v-if="serviceOrder.serviceDetails.garden.bottlenecks">
                        <td><label>{{$t('Bottlenecks')}}: </label></td>
                        <td>{{$t(boolValue(serviceOrder.serviceDetails.garden.bottlenecks))}}</td>
                      </tr>
                      <tr>
                        <td><label>{{$t('Incline in %')}}: </label></td>
                        <td>{{serviceOrder.serviceDetails.garden.incline}}</td>
                      </tr>
                      <tr>
                        <td><label>{{$t('Lawn type')}}: </label></td>
                        <td>
                          <span v-for="(type, index) in serviceOrder.serviceDetails.garden.lawnTypes" :key="index">{{type}}&nbsp;</span>
                        </td>
                      </tr>
                      <tr>
                        <td><label>{{$t('Edges')}}: </label></td>
                        <td>
                          <span v-for="(edge, index) in serviceOrder.serviceDetails.garden.edges" :key="index">{{edge}}&nbsp;</span>
                        </td>
                      </tr>
                      <tr>
                        <td><label>{{$t('Specials')}}: </label></td>
                        <td>
                          <span v-for="(special, index) in serviceOrder.serviceDetails.garden.specials" :key="index">{{special}}&nbsp;</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </v-col>
                  <v-col v-if="serviceOrder.serviceDetails.suppliedAccessories">
                    <h4>{{$t('Supplied Accessories')}}</h4>
                    <table>
                      <caption></caption>
                      <thead>
                      <tr>
                        <th id="default"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <label>{{$t('Battery')}}: </label>
                        </td>
                        <td class="value">
                          <strong>{{ $t(boolValue(serviceOrder.serviceDetails.suppliedAccessories.ownsIncludedBattery)) }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>{{$t('Charging station')}}: </label>
                        </td>
                        <td class="value">
                          <strong>{{ $t(boolValue(serviceOrder.serviceDetails.suppliedAccessories.ownsIncludedChargingStation)) }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label>{{$t('Power supply')}}: </label>
                        </td>
                        <td class="value">
                          <strong>{{ $t(boolValue(serviceOrder.serviceDetails.suppliedAccessories.ownsIncludedPowerAdapter)) }}</strong>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </v-col>
                  <v-col v-if="serviceOrder.serviceDetails.availableAccessories">
                    <h4>{{$t('Available Accessories')}}</h4>
                    <table>
                      <caption></caption>
                      <thead>
                      <tr>
                        <th id="default"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="serviceOrder.serviceDetails.availableAccessories.batteries">
                        <td><label>{{$t('Batteries')}}: </label></td>
                        <td>{{serviceOrder.serviceDetails.availableAccessories.batteries}}</td>
                      </tr>
                      <tr v-if="serviceOrder.serviceDetails.availableAccessories.perimeterWire">
                        <td><label>{{$t('Perimeter wire in m')}}: </label></td>
                        <td>{{serviceOrder.serviceDetails.availableAccessories.perimeterWire}}</td>
                      </tr>
                      <tr v-if="serviceOrder.serviceDetails.availableAccessories.pins">
                        <td><label>{{$t('Pins')}}: </label></td>
                        <td>{{serviceOrder.serviceDetails.availableAccessories.pins}}</td>
                      </tr>
                      <tr>
                        <td><label>{{$t('Garage')}}: </label></td>
                        <td>{{$t(boolValue(serviceOrder.serviceDetails.availableAccessories.garage))}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </v-col>
                  <v-col v-if="serviceOrder.serviceDetails.wireInformation">
                    <h4>{{$t('Wire Information')}}</h4>
                    <table>
                      <caption></caption>
                      <thead>
                      <tr>
                        <th id="default"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><label>{{ $t('Wire Laid') }}</label></td>
                        <td>{{ serviceOrder.serviceDetails.wireInformation.wireLaid }}</td>
                      </tr>
                      <tr>
                        <td><label>{{ $t('Who Installed') }}</label></td>
                        <td>{{ serviceOrder.serviceDetails.wireInformation.whoInstalled }}</td>
                      </tr>
                      <tr>
                        <td><label>{{ $t('Wire Installation Date') }}</label></td>
                        <td>{{ formatDate(serviceOrder.serviceDetails.wireInformation.wireInstallationDate) }}</td>
                      </tr>
                      <tr>
                        <td><label>{{ $t('Wire length') }}</label></td>
                        <td>{{ serviceOrder.serviceDetails.wireInformation.wireLength }} m</td>
                      </tr>
                      </tbody>
                    </table>
                  </v-col>
                  <v-col v-if="serviceOrder.serviceDetails.ownedMowers && serviceOrder.serviceDetails.ownedMowers.length > 0">
                    <h4>{{$t('Owned Mowers')}}</h4>
                    <div v-for="(mower, index) in serviceOrder.serviceDetails.ownedMowers" :key="index">
                      <div v-if="mower.itemNo && mower.identNo">
                        <h4 v-if="mower.description">{{ mower.description }}</h4>
                        <div v-if="mower.serialNo">{{ mower.serialNo }}</div>
                        <div>{{ mower.itemNo }} {{ mower.identNo }}</div>
                      </div>
                      <div v-else-if="mower.name">
                        {{mower.name}}
                      </div>
                      <div v-else>
                        {{ $t('Customer owns a robot lawnmower') }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>

          </div>
          <forward-to-support
              :type="'roboService'"
              :subject="roboService.orderIdentifier"
          />
        </div>
      </v-container>
    </v-card>
    <v-btn
        @click="$router.back()"
        class="back-btn"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{$t('Back')}}
    </v-btn>
  </v-container>
</template>

<script>
import SearchService from "@/services/SearchService";
import {formatDate, formatMoney} from "@/services/globalFunctions";
import ForwardToSupport from "@/components/base/ForwardToSupport";

export default {
  name: "RoboServiceDetail",
  components: {
    ForwardToSupport
  },
  data() {
    return {
      el: 0,
      roboService: null,
      message: '',
      messageType: ''
    }
  },
  mounted() {
    const id = this.$route.params.id
    SearchService.getServiceOrder(id)
        .then(value => {
          this.roboService = value
          this.validateSteps()
        })
        .catch(reason => {
          if (reason['errors'] !== undefined) {
            this.$router.push({
              name: 'search',
              query: { message: 'default_error_message' }
            })
          } else {
            console.log(reason)
          }
        });
  },
  methods: {
    formatDate(dateString) {
      return formatDate(dateString);
    },
    formatMoney(total) {
      return formatMoney(total * 100, "EUR");
    },
    boolValue(value) {
      if (value) {
        return "Yes"
      }
      return "No"
    },
    loadItemImage(item) {
      if (item.productImage) {
        return 'https://assets.einhell.com/im/imf/800/' + item.productImage + '/'
      } else {
        return 'https://assets.einhell.com/im/imf/800/900_347067/'
      }
    },
    validateSteps() {
      if (this.roboService.status === 10) {
        this.el = 1
      } else if (this.roboService.status === 20) {
        this.el = 2
      } else if (this.roboService.status === 30) {
        this.el = 3
      }
    },
  }
}
</script>

<style scoped>

</style>
