import {reactive} from "vue";
import {WarrantySearchModelType} from "@/types/WarrantySearchModelType";
import {WarrantySelectableType} from "@/types/WarrantySelectableType";
import {CicSearchModelType} from "@/types/CicSearchModelType";

interface StoreType {
    cicSearchModel: CicSearchModelType
    warrantySearchModel: WarrantySearchModelType|null
    warranty: WarrantySelectableType
}

export const store = reactive<StoreType>({
    cicSearchModel: {
        number:'',
        email:'',
    },
    warrantySearchModel: {
        email:'',
        serialnumber:'',
        firstname:'',
        lastname:'',
    },
    warranty: {
        availableCountries: [],
        availableLocales: [
            'en'
        ],
        availableDomains: []
    }
})
