import Vue from 'vue';
import Router from 'vue-router';
import SearchView from './components/SearchView.vue'
import OrderList from './components/order/OrderList.vue'
import OrderDetail from './components/order/OrderDetail.vue'
import RetoureList from './components/retoure/RetoureList.vue'
import RetoureDetail from './components/retoure/RetoureDetail.vue'
import CustomerComplaintList from './components/customercomplaint/CustomerComplaintList.vue'
import CustomerComplaintDetail from './components/customercomplaint/CustomerComplaintDetail.vue'
import RoboServiceList from '@/components/roboservice/RoboServiceList.vue'
import RoboServiceDetail from '@/components/roboservice/RoboServiceDetail.vue'
import KeycloakUserDetail from '@/components/myeinhell/KeycloakUserDetail.vue'
import KeycloakUserList from '@/components/myeinhell/KeycloakUserList.vue'
import HomePage from '@/components/HomePage.vue'
import WarrantyView from "@/components/WarrantyView.vue";
import UserList from "@/components/warranty/user/UserList.vue";
import UserDetail from "@/components/warranty/user/UserDetail.vue";
import UserCreate from "@/components/warranty/user/UserCreate.vue";
import {KeycloakService, defaultRoles, warrantyReadRoles, warrantyEditRoles} from "@/services/KeycloakService";

Vue.use(Router);

const router = new Router({

    routes: [
        {
            path: '/',
            name: 'home',
            component: HomePage
        },
        {
            path: '/search',
            name: 'search',
            component: SearchView,
            meta: {
                roles: defaultRoles
            },
        },
        {
            path: '/order',
            name: 'order_list',
            component: OrderList,
            meta: {
                roles: defaultRoles
            },
        },
        {
            path: '/order/:id',
            name: 'order_detail',
            component: OrderDetail,
            meta: {
                roles: defaultRoles
            },
        },
        {
            path: '/retoure',
            name: 'retoure_list',
            component: RetoureList,
            meta: {
                roles: defaultRoles
            },
        },
        {
            path: '/retoure/:id',
            name: 'retoure_detail',
            component: RetoureDetail,
            meta: {
                roles: defaultRoles
            },
        },
        {
            path: '/customercomplaint',
            name: 'customer_complaint_list',
            component: CustomerComplaintList,
            meta: {
                roles: defaultRoles
            },
        },
        {
            path: '/customercomplaint/:id',
            name: 'customer_complaint_detail',
            component: CustomerComplaintDetail,
            meta: {
                roles: defaultRoles
            },
        },
        {
            path: '/roboservice',
            name: 'roboservice_list',
            component: RoboServiceList,
            meta: {
                roles: defaultRoles
            },
        },
        {
            path: '/roboservice/:id',
            name: 'roboservice_detail',
            component: RoboServiceDetail,
            meta: {
                roles: defaultRoles
            },
        },
        {
            path: '/user/:email',
            name: 'user_list',
            component: KeycloakUserList,
            meta: {
                roles: defaultRoles
            },
        },
        {
            path: '/user/:realm/:id',
            name: 'user_detail',
            component: KeycloakUserDetail,
            meta: {
                roles: defaultRoles
            },
        },
        {
            path: '/warranty',
            name: 'warranty',
            component: WarrantyView,
            meta: {
                roles: [...warrantyReadRoles]
            },
        },
        {
            path: '/warranty/users',
            name: 'warranty_users',
            component: UserList,
            meta: {
                roles: [...warrantyReadRoles]
            },
        },
        {
            path: '/warranty-user/create',
            name: 'warranty_user_create',
            component: UserCreate,
            meta: {
                roles: warrantyEditRoles
            },
        },
        {
            path: '/warranty/user/:id',
            name: 'warranty_user_detail',
            component: UserDetail,
            meta: {
                roles: [...warrantyReadRoles]
            },
        },
        {
            path: '*',
            redirect: '/'
            // 404
        },
    ]
})

router.beforeEach((to, from, next) => {
    //check page is protected or not
    if (to.meta && to.meta.roles) {
        const roles = KeycloakService.roles
        // @ts-ignore
        if (to.meta.roles.some(r => roles.includes(r))) {
            return next()
        } else {
            router.push({
                name: 'home',
                query: { message: 'Unauthorized' }
            })
        }
    } else {
        return next()
    }
});

export default router
