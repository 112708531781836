<template>
  <v-container -max-width-limited>
    <v-card v-if="user" class="mt-5 mb-5" :color="'#eee'">
      <v-container>
        <div class="col-md-12 clearfix order-information">
          <div class="entry-edit-head">
            <h2 class="icon-head head-account">
              <span>{{ i18n.t('User Information') }}</span>
            </h2>
          </div>
          <v-row>
            <v-container>
              <v-row>
                <v-col>
                  <table class="form-list" v-if="user">
                    <tbody>
                    <tr>
                      <td>
                        <label>{{ i18n.t('E-Mail') }}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ user.email }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{ i18n.t('Firstname') }}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ user.firstname }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{ i18n.t('Lastname') }}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ user.lastname }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{ i18n.t('Zip') }}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ user.zip }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{ i18n.t('City') }}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ user.city }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{ i18n.t('Street') }}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ user.street }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{ i18n.t('Country') }}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ user.country }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{ i18n.t('Phone') }}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ user.phone }}</strong>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <label>{{ i18n.t('Locale') }}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ user.origLocale }}</strong>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </v-col>
                <v-col>
                  <v-row justify="end" v-if="checkEditRole()">
                    <v-dialog
                        v-model="dialogMergeUser"
                        persistent
                        width="auto"
                        v-if="warranties.length > 0 && checkEditRole()"
                    >
                      <template v-slot:activator="{ props }">
                        <v-btn
                            color="gray"
                            class="mr-2"
                            v-bind="props"
                            @click="dialogMergeUser = true"
                        >
                          {{ i18n.t("Merge User") }}
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          {{ i18n.t('Merge User') }}
                        </v-card-title>
                        <v-card-text>
                          <v-container v-if="searchedMergeUser.length > 0">
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                <tr>
                                  <th>{{ i18n.t('Name') }}</th>
                                  <th>{{ i18n.t('E-Mail') }}</th>
                                  <th>{{ i18n.t('Address') }}</th>
                                  <th>{{ i18n.t('Street') }}</th>
                                  <th>{{ i18n.t('Action') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(userToMerge,index) in searchedMergeUser"
                                    :key="index"
                                    v-show="user.id != userToMerge.id">
                                  <td>{{ userToMerge.firstname }}
                                    {{ userToMerge.lastname }}
                                  </td>
                                  <td>{{ userToMerge.email }}</td>
                                  <td>{{
                                      userToMerge.country.toUpperCase()
                                    }}-{{ userToMerge.zip }} {{ userToMerge.city }}
                                  </td>
                                  <td>{{ userToMerge.street }}</td>
                                  <td>
                                    <v-btn @click="mergeUser(userToMerge.id)">
                                      {{ i18n.t('Merge') }}
                                    </v-btn>
                                  </td>
                                </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                            <v-btn @click="searchedMergeUser = []">{{
                                i18n.t("Clear")
                              }}
                            </v-btn>
                          </v-container>
                          <v-form v-else @submit.prevent="searchMergeuser">
                            <v-row>
                              <v-col>
                                <v-text-field
                                    v-model="searchModel.email"
                                    :label="i18n.t('E-Mail')"
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                    v-model="searchModel.serialnumber"
                                    :label="i18n.t('SerialNo')"
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                    v-model="searchModel.firstname"
                                    :label="i18n.t('Firstname')"
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-text-field
                                    v-model="searchModel.lastname"
                                    :label="i18n.t('Lastname')"
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-btn block
                                       type="submit"
                                >
                                  {{ $t('Search') }}
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              color="green-darken-1"
                              variant="text"
                              @click="dialogMergeUser = false"
                          >
                            {{ i18n.t('Close') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                        v-model="editUser"
                        max-width="1000px"
                    >
                      <template v-slot:activator="{ props }">
                        <v-btn
                            color="blue"
                            dark
                            class="mr-2"
                            v-bind="props"
                            @click="editUser = true"
                        >
                          {{ i18n.t("Edit User") }}
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="8" md="4">
                                <v-text-field
                                    v-model="user.email"
                                    :label="i18n.t('E-Mail')"
                                    @change="user.email = removeWhiteSpace(user.email)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="8" md="4">
                                <v-text-field
                                    v-model="user.firstname"
                                    :label="i18n.t('Firstname')"
                                    @change="user.firstname = removeWhiteSpace(user.firstname)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="8" md="4">
                                <v-text-field
                                    v-model="user.lastname"
                                    :label="i18n.t('Lastname')"
                                    @change="user.lastname = removeWhiteSpace(user.lastname)"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="8" md="4">
                                <v-text-field
                                    v-model="user.zip"
                                    :label="i18n.t('Zip')"
                                    @change="user.zip = removeWhiteSpace(user.zip)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="8" md="4">
                                <v-text-field
                                    v-model="user.city"
                                    :label="i18n.t('City')"
                                    @change="user.city = removeWhiteSpace(user.city)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="8" md="4">
                                <v-text-field
                                    v-model="user.street"
                                    :label="i18n.t('Street')"
                                    @change="user.street = removeWhiteSpace(user.street)"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="8" md="4">
                                <v-select
                                    v-model="user.country"
                                    :items="store.warranty.availableCountries"
                                    :label="i18n.t('Country')"
                                    @change="selectCountry"
                                ></v-select>
                              </v-col>
                              <v-col cols="12" sm="8" md="4">
                                <v-text-field
                                    v-model="user.phone"
                                    :label="i18n.t('Phone')"
                                    @change="user.phone = removeWhiteSpace(user.phone)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="8" md="4">
                                <v-select
                                    v-model="user.locale"
                                    :items="store.warranty.availableLocales"
                                    :label="i18n.t('Locale')"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              color="gray"
                              variant="text"
                              @click="closeEditUser"
                          >
                            {{ i18n.t('Close') }}
                          </v-btn>
                          <v-btn
                              color="green"
                              variant="text"
                              @click="saveUser"
                          >
                            {{ i18n.t('Save') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                        v-model="dialogDeleteUser"
                        persistent
                        width="auto"
                        v-if="checkDeleteRole()"
                    >
                      <template v-slot:activator="{ props }">
                        <v-btn
                            color="red"
                            v-bind="props"
                            @click="dialogDeleteUser = true"
                        >
                          {{ i18n.t("Delete User") }}
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          {{ i18n.t("Do you realy want to delete the user?") }}
                        </v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              color="gray"
                              variant="text"
                              @click="dialogDeleteUser = false"
                          >
                            {{ i18n.t('Disagree') }}
                          </v-btn>
                          <v-btn
                              color="red"
                              variant="text"
                              @click="deleteUser"
                          >
                            {{ i18n.t('Agree') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
          <v-row>
            <div class="col-md-12 clearfix warranty-items">
              <v-data-table
                  :headers="headers"
                  :items="warranties"
                  :sort-by="[{ key: 'serialNumber', order: 'asc' }]"
                  :search="warrantySearch"
                  class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar
                      flat
                  >
                    <v-toolbar-title>{{ i18n.t('Warranties') }}</v-toolbar-title>
                    <v-spacer />
                    <v-text-field
                        v-model="warrantySearch"
                        append-icon="mdi-magnify"
                        :label="i18n.t('Search Warranties')"
                        single-line
                        hide-details
                    ></v-text-field>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-select
                        v-model="downloadableLocale"
                        :items="store.warranty.availableLocales"
                        dense
                        outlined
                        hide-details
                        style="max-width: 200px"
                        :label="i18n.t('Locale for Download')"
                    ></v-select>
                    <template v-if="checkEditRole()">
                      <v-divider
                          class="mx-4"
                          inset
                          vertical
                      ></v-divider>
                      <v-dialog
                          v-model="editWarrantyDialog"
                          max-width="600px"
                      >
                        <template v-slot:activator="{ props }">
                          <v-btn
                              color="light-blue"
                              dark
                              class="mb-2"
                              v-bind="props"
                              @click="newWarranty"
                          >
                            {{ i18n.t('New Warranty') }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">{{ i18n.t("Warranty") }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                  <v-text-field
                                      v-model="editedItem.serialNumber"
                                      :label="i18n.t('SerialNo')+ ' *'"
                                      @change="editedItem.serialNumber = removeWhiteSpace(editedItem.serialNumber)"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                  <v-select
                                      v-model="editedItem.domain"
                                      :items="store.warranty.availableDomains"
                                      :label="i18n.t('Domain')+ ' *'"
                                  ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                  <v-text-field
                                      v-model="editedItem.merchant"
                                      :label="i18n.t('Merchant')+ ' *'"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                  <v-text-field
                                      v-model="editedItem.invoiceNo"
                                      :label="i18n.t('InvoiceNo')+ ' *'"
                                      :min="1"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="8"
                                >
                                  <date-picker-input
                                      @update:modelValue="(event) => { editedItem.purchaseDate = event }"
                                      v-model="editedItem.purchaseDate"
                                      :value="editedItem.purchaseDate"
                                      :model-value="editedItem.purchaseDate"
                                      :label="i18n.t('Purchase Date')+ ' *'"
                                      :disabled-dates="disableFutureDate"
                                  ></date-picker-input>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue-darken-1"
                                variant="text"
                                @click="closeWarrantyEditDialog"
                            >
                              {{ i18n.t('Cancel') }}
                            </v-btn>
                            <v-btn
                                color="green"
                                variant="text"
                                @click="saveWarranty"
                            >
                              {{ i18n.t('Save') }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="deleteWarrantyDialog" max-width="500px">
                        <v-card>
                          <v-card-title class="text-h5">{{
                              i18n.t("Are you sure you want to delete this warranty?")
                            }}
                          </v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="gray" variant="text"
                                   @click="closeWarrantyDeleteDialog">{{
                                i18n.t("Disagree")
                              }}
                            </v-btn>
                            <v-btn color="red" variant="text" @click="deleteWarrantyConfirm">
                              {{ i18n.t('Agree') }}
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.warranty`]="{ item }">
                  <li v-for="it in getWarrantyText(item)" :key="it.warrantyType">
                    {{ it }}
                  </li>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <template v-if="checkEditRole()">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="me-2"
                            @click="editWarranty(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>{{ i18n.t("Edit Warranty") }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            class="me-2"
                            @click="openWarrantyDeleteDialog(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>{{ i18n.t("Delete Warranty") }}</span>
                    </v-tooltip>
                  </template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          small
                          class="me-2"
                          @click="downloadWarranty(item.id, item.serialNumber)"
                          v-bind="attrs"
                          v-on="on"
                      >
                        mdi-download
                      </v-icon>
                    </template>
                    <span>{{ i18n.t("Download Warranty Certificate") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          small
                          class="me-2"
                          @click="sendWarranty(item.id)"
                          v-bind="attrs"
                          v-on="on"
                      >
                        mdi-email
                      </v-icon>
                    </template>
                    <span>{{ i18n.t("Send E-Mail") }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </div>
          </v-row>
          <v-row justify="end" class="mt-3">
            <v-dialog
                v-if="warranties.length > 0"
                v-model="dialogDownloadAll"
                persistent
                width="auto"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                    @click="dialogDownloadAll = true"
                    v-bind="props"
                >
                  <v-icon>mdi-download</v-icon>
                  {{ i18n.t('Download All') }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  {{ i18n.t('Download All Certificate') }}
                </v-card-title>
                <v-card-text>
                  <v-select
                      :label="i18n.t('Locale')"
                      v-model="downloadableLocale"
                      :items="store.warranty.availableLocales"
                  ></v-select>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="green-darken-1"
                      variant="text"
                      @click="dialogDownloadAll = false"
                  >
                    {{ i18n.t('Close') }}
                  </v-btn>
                  <v-btn
                      color="green"
                      variant="text"
                      @click="downloadAll()"
                  >
                    {{ i18n.t('Download') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>
      </v-container>
    </v-card>
    <v-btn
        @click="router.push({name: 'warranty'})"
        class="back-btn"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ i18n.t('Back') }}
    </v-btn>
    <v-snackbar
        v-model="snackbar"
        vertical
        right
        :color="messageType"
        :location="'top'"
    >
      <div class="text-subtitle-1 pb-2">{{ i18n.t(message) }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn
            color="black"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          {{ i18n.t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script setup lang="ts">
import {WarrantyService} from '../../../services/WarrantyService'
import {onMounted, reactive, ref} from "vue";
import {useRoute, useRouter} from 'vue-router/composables'
import i18n from "@/i18n";
import {WarrantyUserType} from "@/types/WarrantyUserType";
import {WarrantyType} from "@/types/WarrantyType";
import DatePickerInput from "@/components/base/DatePickerInput.vue";
import {WarrantySearchModelType} from "@/types/WarrantySearchModelType";
import {WarrantySaveType} from "@/types/WarrantySaveType";
import {store} from "@/store";
import {WarrantyLocationInfoService} from "@/services/WarrantyLocationInfoService";
import {KeycloakService, warrantyDeleteRoles, warrantyEditRoles} from "@/services/KeycloakService";
import {Warranty} from "@/types/RemoteTypes";

const router = useRouter()
const route = useRoute()

const message = ref<string | (string | null)[]>('')
const messageType = ref<string | (string | null)[]>('yellow')
const userId = ref('')
const user: WarrantyUserType | any = ref({})
const editedItem: WarrantyType | any = ref({
  serialNumbers: '',
  thirdPartyAllowed: false
})
const editedIndex = ref<number>(-1)
const editUser = ref(false)
const dialogDeleteUser = ref(false)
const dialogMergeUser = ref(false)
const dialogAddWarranty = ref(false)
const dialogDownloadAll = ref(false)
const editWarrantyDialog = ref(false)
const deleteWarrantyDialog = ref(false)
const snackbar = ref(false)
const warranties = ref([])
const headers = ref([
  {text: i18n.t('SerialNo'), value: 'serialNumber', sortable: false},
  {text: i18n.t('Description'), value: 'description', sortable: false},
  {text: i18n.t('Tpg'), value: 'tpgName', sortable: false},
  {text: i18n.t('Domain'), value: 'domain', sortable: false},
  {text: i18n.t('Merchant'), value: 'merchant', sortable: false},
  {text: i18n.t('InvoiceNo'), value: 'invoiceNo', sortable: false},
  {text: i18n.t('Registration Date'), value: 'registrationDate', sortable: false},
  {text: i18n.t('Warrantyduration'), value: 'warranty', sortable: false},
  {text: i18n.t('Actions'), value: 'actions', sortable: false},
])
const searchedMergeUser = ref([])
const searchModel = reactive<WarrantySearchModelType>({
  email: '',
  serialnumber: '',
  firstname: '',
  lastname: '',
})
const downloadableLocale = ref<string>('')

const warrantySearch = ref<string>('')

onMounted(() => {
  WarrantyLocationInfoService.getCountries()
  // @ts-ignore
  userId.value = route.params.id
  WarrantyService.getUser(userId.value)
      .then(value => {
        value.origLocale = value.locale
        if (value.locale) {
          value.locale = value.locale.substring(0, 2)
        }
        user.value = value
        user.value.country = user.value.country.toLowerCase()
        downloadableLocale.value = value.locale
        getWarranties()
        WarrantyLocationInfoService.getLocales(user.value.country)
        WarrantyLocationInfoService.getDomains(user.value.country)
      })
      .catch(reason => {
        if (reason['errors'] !== undefined) {
          console.log(reason)
          router.push({
            name: 'warranty',
            query: {message: reason['errors']}
          })
        } else {
          console.log(reason)
          router.push({
            name: 'warranty',
            query: {message: "User could not be loaded, please try again later"}
          })
        }
      });
})

function getWarranties() {
  WarrantyService.getUserWarranties(userId.value, i18n.locale)
      .then(value => {
        warranties.value = value.data
      })
      .catch(reason => {
        if (reason['errors'] !== undefined) {
          console.log(reason)
          displayMessage(reason['errors'])
        } else {
          console.log(reason)
          displayMessage("Warranties could not be loaded, please try again later")
        }
      });
}

function saveUser() {
  message.value = ""
  if (validateUser(user.value)) {
    WarrantyService.updateUser(userId.value, user.value)
        .then(() => {
          editUser.value = false
          displayMessage('User saved', 'success')
          getWarranties()
          downloadableLocale.value = user.value.locale
          WarrantyLocationInfoService.getLocales(user.value.country)
          WarrantyLocationInfoService.getDomains(user.value.country)
        })
        .catch(reason => {
          if (reason['msg'] !== undefined) {
            displayMessage(reason['msg'])
          } else {
            displayMessage('default_error_message')
          }
        });
  }
}

function validateUser(user: WarrantyUserType) {
  if (!user.email) {
    displayMessage('E-mail must not be empty')
    return false;
  }
  if (!user.firstname) {
    displayMessage('Firstname must not be empty')
    return false;
  }
  if (!user.lastname) {
    displayMessage('Lastname must not be empty')
    return false;
  }
  if (!user.zip) {
    displayMessage('Zip must not be empty')
    return false;
  }
  if (!user.city) {
    displayMessage('City must not be empty')
    return false;
  }
  if (!user.street) {
    displayMessage('Street must not be empty')
    return false;
  }
  if (!user.country) {
    displayMessage('Country must not be empty')
    return false;
  }
  if (!user.locale) {
    displayMessage('Locale must not be empty')
    return false;
  }
  return true;
}

function deleteUser() {
  message.value = ""
  WarrantyService.deleteUser(userId.value)
      .then(() => {
        dialogDeleteUser.value = false
        router.push({
          name: 'warranty',
        })
      })
      .catch(reason => {
        dialogDeleteUser.value = false
        if (reason['msg'] !== undefined) {
          displayMessage(reason['msg'])
        } else {
          console.log(reason)
          displayMessage('default_error_message')
        }
      });
}

function searchMergeuser() {
  message.value = ""
  WarrantyService.search(searchModel)
      .then(value => {
        searchedMergeUser.value = value
      })
      .catch(reason => {
        if (reason['msg'] !== undefined) {
          displayMessage(reason['msg'])
        } else {
          displayMessage('default_error_message')
        }
      });
}

function createWarranty() {
  message.value = ""
  editedItem.value.serialNumbers = [editedItem.value.serialNumber]
  editedItem.value.thirdPartyAllowed = false
  const newWarranty = {
    serialNumbers: [editedItem.value.serialNumber],
    domain: editedItem.value.domain,
    merchant: editedItem.value.merchant,
    invoiceNo: editedItem.value.invoiceNo,
    purchaseDate: editedItem.value.purchaseDate,
    thirdPartyAllowed: false,
  }

  WarrantyService.createWarranty(userId.value, newWarranty)
      .then(value => {
        if (value["success"].length > 0) {
          displayMessage('Warranty created', 'success')
          editedItem.value = Object.assign({}, {})
          warrantySearch.value = ''
          getWarranties()
        }
        if (value["failed"].length > 0) {
          value["failed"].forEach(function (errorMessage: any) {
            console.log(errorMessage)
            displayMessage(errorMessage.errorType, 'error')
          })
        }

      })
      .catch(reason => {
        dialogAddWarranty.value = false
        if (reason['msg'] !== undefined) {
          displayMessage(reason['msg'], 'red')
        } else {
          console.log(reason)
          displayMessage('default_error_message')
        }
      });
}

function mergeUser(newUserId: string) {
  message.value = ""
  WarrantyService.mergeWarranty(userId.value, newUserId)
      .then(value => {
        console.log(value)
        dialogAddWarranty.value = false
        router.push({
          name: "warranty_user_detail",
          params: {id: newUserId},
        })
        window.location.reload()
      })
      .catch(reason => {
        dialogAddWarranty.value = false
        if (reason['msg'] !== undefined) {
          displayMessage(reason['msg'], 'red')
        } else {
          console.log(reason)
          displayMessage('default_error_message')
        }
      });
}

function editWarranty(item: WarrantyType) {
  // @ts-ignore
  editedIndex.value = warranties.value.indexOf(item)
  editedItem.value = Object.assign({}, item)
  editWarrantyDialog.value = true
}

function newWarranty() {
  editedItem.value = {}
  if (store.warranty.availableDomains.length == 1) {
    editedItem.value.domain = store.warranty.availableDomains[0]
  }
  editWarrantyDialog.value = true
}

function updateWarranty(warranty: WarrantySaveType) {
  // @ts-ignore
  const warrantyId = warranty.id
  const warrantyToSave: WarrantySaveType = {
    purchaseDate: warranty.purchaseDate,
    serialNumber: warranty.serialNumber,
    domain: warranty.domain,
    invoiceNo: warranty.invoiceNo,
    merchant: warranty.merchant,
    thirdPartyAllowed: warranty.thirdPartyAllowed,
  }

  message.value = ""
  WarrantyService.updateWarranty(userId.value, warrantyId, user.value.locale, warrantyToSave)
      .then(() => {
        displayMessage('Warranty updated', 'success')
        editedItem.value = Object.assign({}, {})
        getWarranties()
      })
      .catch(reason => {
        dialogAddWarranty.value = false
        if (reason['msg'] !== undefined) {
          displayMessage(reason['msg'], 'red')
        } else {
          console.log(reason)
          displayMessage('default_error_message')
        }
      }).finally(() => {
    editedItem.value = Object.assign({}, {})
  });
}

function deleteWarrantyConfirm() {
  // @ts-ignore
  const warrantyId = editedItem.value.id
  message.value = ""
  WarrantyService.deleteWarranty(userId.value, warrantyId)
      .then(() => {
        closeWarrantyDeleteDialog()
        displayMessage('Warranty deleted', 'success')
        getWarranties()
      })
      .catch(reason => {
        dialogAddWarranty.value = false
        if (reason['msg'] !== undefined) {
          displayMessage(reason['msg'], 'red')
        } else {
          console.log(reason)
          displayMessage('default_error_message')
        }
      });
}

function downloadAll() {
  warranties.value.forEach(function (warranty) {
    // @ts-ignore
    downloadWarranty(warranty.id, warranty.serialNumber)
  })
  dialogDownloadAll.value = false
}

function downloadWarranty(warrantyId: string, serialNumber: string) {
  message.value = ""
  WarrantyService.download(userId.value, warrantyId, downloadableLocale.value)
      .then(value => {
        // @ts-ignore
        download(serialNumber + ".pdf", value)
      })
      .catch(reason => {
        dialogAddWarranty.value = false
        if (reason['msg'] !== undefined) {
          displayMessage(reason['msg'], 'red')
        } else {
          console.log(reason)
          displayMessage('default_error_message')
        }
      });
}

function sendWarranty(warrantyId: string) {
  // @ts-ignore
  const locale = user.value.locale
  message.value = ""
  WarrantyService.sendEmail(userId.value, warrantyId, locale)
      .then(() => {
        displayMessage('Email send successfully', 'success')
      })
      .catch(reason => {
        dialogAddWarranty.value = false
        if (reason['msg'] !== undefined) {
          displayMessage(reason['msg'], 'red')
        } else {
          console.log(reason)
          displayMessage('default_error_message')
        }
      });
}

// eslint-disable-next-line no-undef
function download(filename: string, data: BlobPart) {
  let a = document.createElement("a");
  let file = new Blob([data]);
  const fileURL = window.URL.createObjectURL(file);
  a.href = fileURL;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(fileURL);
  a.remove()
}

function saveWarranty() {
  if (!editedItem.value.serialNumber) {
    displayMessage('Serial number is a mandatory field', 'warning')
    return
  }
  if (!editedItem.value.domain) {
    displayMessage('Domain is a mandatory field', 'warning')
    return
  }
  if (!editedItem.value.merchant) {
    displayMessage('Merchant is a mandatory field', 'warning')
    return
  }
  if (!editedItem.value.invoiceNo) {
    displayMessage('Invoice No is a mandatory field', 'warning')
    return
  }
  if (editedItem.value.invoiceNo.length < 1) {
    displayMessage('Invoice No must be more than 5 characters', 'warning')
    return
  }
  if (!editedItem.value.purchaseDate) {
    displayMessage('Purchasedate is a mandatory field', 'warning')
    return
  }

  if (editedIndex.value > -1) {
    updateWarranty(editedItem.value)
  } else {
    createWarranty()
  }
  closeWarrantyEditDialog()
}

function selectCountry(country: string) {
  WarrantyLocationInfoService.getLocales(country)
  user.value.locale = ''
}

function closeEditUser() {
  editUser.value = false
  WarrantyService.getUser(userId.value)
      .then(value => {
        user.value = value
        user.value.country = user.value.country.toLowerCase()
        downloadableLocale.value = value.locale
      })
      .catch(reason => {
        if (reason['errors'] !== undefined) {
          console.log(reason)
          router.push({
            name: 'warranty',
            query: {message: reason['errors']}
          })
        } else {
          console.log(reason)
          router.push({
            name: 'warranty',
            query: {message: "User could not be loaded, please try again later"}
          })
        }
      });
}

function closeWarrantyEditDialog() {
  editWarrantyDialog.value = false
  editedIndex.value = -1
}

function closeWarrantyDeleteDialog() {
  editedItem.value = Object.assign({}, {})
  editedIndex.value = -1
  deleteWarrantyDialog.value = false
}

function openWarrantyDeleteDialog(item: WarrantyType) {
  editedItem.value = Object.assign({}, item)
  deleteWarrantyDialog.value = true
}

const displayMessage = (messageString: string | (string | null)[], type: string = "warning") => {
  message.value = messageString
  messageType.value = type
  snackbar.value = true
}

const checkEditRole = () => {
  return KeycloakService.roles.some(r => warrantyEditRoles.includes(r))
}

const checkDeleteRole = () => {
  return KeycloakService.roles.some(r => warrantyDeleteRoles.includes(r))
}

function getWarrantyText(item: Warranty): string[] {
  const result: string[] = []
  for (let duration of item.warranties) {
    result.push(`${duration.warrantyType}: ${duration.warrantyDuration}`)
  }

  return result
}

const disableFutureDate = (date: Date) => {
    return date > new Date(new Date().setHours(0, 0, 0, 0));
}

function removeWhiteSpace(text:string) {
  return text.trim();
}
</script>
