<template>
    <div class="vue-datepicker">
        <p v-if="date">{{ label }}</p>
        <date-picker
                v-model="date"
                @input="emitEvent"
                :disabled-date="disabledDates"
                valueType="format"
                :clearable="false"
                :placeholder="label"
        ></date-picker>
    </div>
</template>

<script setup lang="ts">

import {ref, watch} from "vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const props = defineProps({
    modelValue: {
        type: String
    },
    label: {
        type: String
    },
    tooltip: {
        type: String
    },
    required: {
        type: Boolean
    },
    typeable: {
        type: Boolean
    },
    placeholder: {
        type: String
    },
    id: {
        type: String
    },
    locale: {
        type: String
    },
    max: {
        type: String
    },
    disabledDates: {
        type: Object
    }
})

const emit = defineEmits(['update:modelValue'])

const date = ref(props.modelValue)
const show = ref(false)

// @ts-ignore
watch(() => props.modelValue, (first, second) => {
    if (first) {
        date.value = props.modelValue
    }
    if (second) {
        date.value = props.modelValue
    }
})

function emitEvent(event: any) {
    show.value = false
    emit('update:modelValue', event)
}

</script>

<style>
.vue-datepicker{
    position: relative;
}

.vue-datepicker p {
    left: 0px;
    right: auto;
    position: absolute;
    margin-bottom: 0;
}

.vue-datepicker .mx-datepicker {
    padding-top: 12px;
    margin-top: 4px;
}


.vue-datepicker .mx-datepicker  .mx-input{
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.42);
    box-shadow: none;
}
</style>
