<template>
  <v-container -max-width-limited>
      <v-skeleton-loader
              v-if="retoureList.length === 0"
              type="image"
      ></v-skeleton-loader>
    <v-card :color="'#eee'" class="mt-5 mb-5" v-for="retoure in retoureList" :key="retoure.id">
      <v-container>
        <strong>{{ retoure.returnNumber }} <small>({{ retoure.created }})</small></strong>
        <p>
          <span v-if="retoure.statusText">{{ $t('Current status') }}: {{ $t('retoure.status.'+retoure.statusText) }} | </span>
           {{ retoure.itemInfo.itemNo }} |
          {{ retoure.itemInfo.description }} | {{ retoure.address.firstName }} {{ retoure.address.lastName }}
        </p>
        <p v-if="retoure.statusCode === 5">
          <span class="error">
            {{ $t('An error has occurred during the transmission of the data') }}
          </span>
        </p>
        <table v-if="Object.keys(retoure.statusHistory).length > 0">
          <caption></caption>
          <tbody>
          <tr>
            <th scope="date" class="text-left">{{$t('Date')}}</th>
            <th scope="event" class="text-left">{{$t('Event')}}</th>
          </tr>
          <tr v-for="(history,index) in retoure.statusHistory" :key="index">
            <td>{{ history.date }}</td>
            <td>{{ $t('retoure.status.'+history.event) }}</td>
          </tr>
          </tbody>
        </table>
        <v-btn
            @click="showDetails(retoure.id)"
        >
          {{ $t('Show Details') }}
        </v-btn>
      </v-container>
    </v-card>
    <v-btn
        @click="$router.back()"
        class="back-btn"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t('Back') }}
    </v-btn>
  </v-container>
</template>

<script>
import SearchService from '../../services/SearchService'
import {KeycloakService} from "@/services/KeycloakService";

export default {
  name: "RetoureList",
  data: () => ({
    retoureList: [],
  }),
  mounted() {
    const email = this.$route.query.email
    const roles = KeycloakService.roles
    SearchService.getRetoureByEmail(email, roles)
        .then(value => {
          this.retoureList = value.retouren
        })
        .catch(reason => {
          if (reason['errors'] !== undefined) {
            this.$router.push({
              name: 'search',
              query: { message: reason.errors }
            })
          } else {
            console.log(reason)
          }
        });
  },
  methods: {
    showDetails(id) {
      this.$router.push({
        name: 'retoure_detail',
        params: {id: id}
      })
    }
  }
}
</script>

<style scoped>

</style>
