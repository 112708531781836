<template>
    <div>
        <span class="float-left lang-picker-label">{{$t('main.language')}}: </span>
        <v-select class="lang-picker" :items="locales" dense hide-details single-line v-on:change="switchLocale" v-model="selected_locale">
            <template slot="item" slot-scope="data">
                <!-- map "en" to "gb"-->
                <div v-if="data.item === 'en'">
                    <flag iso="gb"/>&nbsp;&nbsp;<span>{{data.item}}</span>
                </div>
                <div v-else>
                    <flag :iso="data.item"/>&nbsp;&nbsp;<span>{{data.item}}</span>
                </div>
            </template>
            <template v-slot:selection="{ item }">
                <!-- map "en" to "gb"-->
                <div v-if="item === 'en'">
                    <flag iso="gb"/>&nbsp;&nbsp;<span>{{item}}</span>
                </div>
                <div v-else>
                    <flag :iso="item"/>&nbsp;&nbsp;<span>{{item}}</span>
                </div>
            </template>
        </v-select>
    </div>
</template>

<script>
    import FlagIcon from 'vue-flag-icon'
    import VueCookie from 'vue-cookie'
    import Vue from 'vue'

    Vue.use(FlagIcon, VueCookie);

    export default {
        name: "LocalSwitcher",
        data() {
            return {
                locales: ['de', 'en'],
                selected_locale: this.$i18n.locale
            }
        },
        methods: {
            switchLocale(locale) {
                if (this.$i18n.locale !== locale) {
                    VueCookie.set('locale', locale, 365)
                    this.$i18n.locale = locale;
                    // EventBus.$emit('locale-changed', locale)
                }
            }
        },
    }
</script>

<style scoped>
    .lang-picker {
        width: 70px;
    }

    .lang-picker-label {
        margin-right: 5px;
    }
</style>
