<template>
  <v-container -max-width-limited>
    <v-skeleton-loader
      v-if="!retoure"
      type="image"
    ></v-skeleton-loader>
    <v-alert
        text
        :type="messageType"
        v-if="message"
    >
      {{ message }}
    </v-alert>
    <v-card :color="'#eee'" v-if="retoure" class="mt-5 mb-5">
      <v-container>
        <div class="col-md-12">
          <div class="servicecenter-page" id="servicecenter">
            <div class="bodytext clearfix">
              <h2>{{$t('Information about the service procedure')}}: {{ retoure.returnNumber }}</h2>
              <div class="col-md-12">
                <v-layout row wrap>
                  <v-flex xs6>
                    <v-container>
                      <div class="retoure-status">
                        <h3 v-if="retoure.statusText">
                          {{ $t('Current status') }}: {{ $t('cc.status.' + retoure.statusText) }}
                        </h3>
                        <h3 v-if="retoure.statusCode == 5" class="error">
                          {{ $t('An error has occurred during the transmission of the data') }}
                        </h3>
                      </div>
                    </v-container>
                  </v-flex>
                </v-layout>
              </div>
            </div>

            <v-stepper v-model="el" class="retoure_stepper">
              <v-stepper-header>
                <v-stepper-step :complete="retoure.statusCode > 10" step="1">
                  {{ $t('retoure.status.Retoure registered') }}
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete="retoure.statusCode > 20" step="2">
                  {{ $t('retoure.status.Return arrived') }}
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete="retoure.statusCode >= 30" step="3">
                  {{ $t('retoure.status.In Process') }}
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete="retoure.statusCode >= 50" step="4">
                  {{ $t('retoure.status.Process completed') }}
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
            <div v-if="retoure.trackingUrl">
              <a :href="retoure.trackingUrl" target="_blank">{{ retoure.trackingUrl }}</a>
            </div>
            <v-row>
              <v-col>
                <h2>{{$t('')}}Customer Infos</h2>
                <table style="width:100%">
                  <caption></caption>
                  <thead>
                  <tr>
                    <th id="default"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{$t('E-Mail')}}</td>
                    <td>{{ retoure.address.email}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Firstname')}}</td>
                    <td>{{ retoure.address.firstName }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Lastname')}}</td>
                    <td>{{ retoure.address.name }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Address')}}</td>
                    <td>{{ retoure.address.street }} {{ retoure.address.streetNumber }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Zip')}}</td>
                    <td>{{ retoure.address.zip }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('City')}}</td>
                    <td>{{ retoure.address.city }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Country')}}</td>
                    <td>{{ $t('main.country.' + retoure.address.country.toLowerCase()) }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Phone')}}</td>
                    <td>{{ retoure.address.phone }}</td>
                  </tr>
                  </tbody>
                </table>
              </v-col>
              <v-col v-if="Object.keys(retoure.statusHistory).length > 0">
                <h2>Logbook</h2>
                <table style="width:100%">
                  <caption></caption>
                  <tbody>
                  <tr>
                    <th id="th-date" class="text-left">{{$t('Date')}}</th>
                    <th id="th-event" class="text-left">{{$t('Event')}}</th>
                  </tr>
                  <tr v-for="(history, index) in retoure.statusHistory" :key="index">
                    <td>{{ history.statusDate.split(" ")[0] }}</td>
                    <td>
                      {{ $t('cc.status.' + history.statusText.toLowerCase()) }}
                      <span v-if="history.serviceProjectIdentifier" style="font-size: 0.8em">{{ $t('cc.service_project_identifier') }}: {{history.serviceProjectIdentifier}}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-container fluid>
                <v-data-table
                  :headers="registeredItemsHeader"
                  :items="retoure.itemInfo"
                  hide-default-footer
                  disable-sort
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>{{ $t('Registered Items')}}</v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:item="{ item }">
                    {{ item.purchaseDate.split("T")[0] }}
                </template>
              </v-data-table>
              </v-container>
            </v-row>
            <v-row class="mt-5">
              <v-container fluid>
                <v-data-table
                  :headers="receivedItemsHeader"
                  :items="getReceivedItems(retoure.retoureInfo.receivedItems)"
                  hide-default-footer
                  disable-sort
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>{{ $t('Received Items')}}</v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:item="{ item }">
                  {{ $t('cc.status.' + item.status.toLowerCase()) }}
                </template>
              </v-data-table>
              </v-container>
            </v-row>
          </div>
          <forward-to-support
              class="mt-5"
              :type="'retoure'"
              :subject="retoure.returnNumber"
              :additional_information="additionalInformations"
          />
        </div>
      </v-container>
    </v-card>
    <v-btn
        @click="$router.back()"
        class="back-btn"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t('Back') }}
    </v-btn>
  </v-container>
</template>

<script>
import SearchService from "../../services/SearchService";
import ForwardToSupport from "@/components/base/ForwardToSupport";

export default {
  name: "RetoureDetail",
  components: {
    ForwardToSupport
  },
  data() {
    return {
      el: 0,
      retoure: null,
      imageUrl: '',
      message: '',
      messageType: '',
      snackbar: false,
      additionalInformations: {},
      registeredItemsHeader: [],
      receivedItemsHeader: [],
    }
  },
  mounted() {
    this.registeredItemsHeader = [
      {text: this.$i18n.t('SerialNo'), value: 'serialNumber'},
      {text: this.$i18n.t('ItemNo'), value: 'itemNumber'},
      {text: this.$i18n.t('IdentNo'), value: 'identNumber'},
      {text: this.$i18n.t('Description'), value: 'description'},
      {text: this.$i18n.t('Purchase Date'), value: 'purchaseDate'},
    ];
    this.receivedItemsHeader = [
      {text: this.$i18n.t('Service Project Identifier'), value: 'serviceProjectIdentifier'},
      {text: this.$i18n.t('ItemNo'), value: 'itemNumber'},
      {text: this.$i18n.t('IdentNo'), value: 'identNumber'},
      {text: this.$i18n.t('Status'), value: 'status'}
    ];
    const id = this.$route.params.id
    SearchService.getCustomerComplaint(id)
        .then(value => {
          this.retoure = value
          this.validateSteps()
          this.loadItemImage()
          this.createAdditionalInformations(value)
        })
        .catch(reason => {
          if (reason['errors'] !== undefined) {
            this.$router.push({
              name: 'search',
              query: { message: 'default_error_message' }
            })
          } else {
            console.log(reason)
          }
        });
  },
  methods: {
    createAdditionalInformations(retoure){
      this.additionalInformations['retoureDate'] = retoure.created;
      this.additionalInformations['retoureState'] = retoure.statusText;
      this.additionalInformations['customer'] = retoure.address.email;
    },
    validateSteps() {
      if (this.retoure.statusCode === 10) {
        this.el = 1
      } else if (this.retoure.statusCode === 20) {
        this.el = 2
      } else if (this.retoure.statusCode > 30 && this.retoure.statusCode < 49) {
        this.el = 3
      }
    },
    loadItemImage() {
      if (this.retoure.itemInfo.assetId) {
        this.imageUrl = 'https://assets.einhell.com/im/imf/800/' + this.retoure.itemInfo.assetId + '/'
      } else {
        this.imageUrl = 'https://assets.einhell.com/im/imf/800/900_347067/'
      }
    },
    getReceivedItems(receivedItems){
      var items = [];
      for (const identifier in receivedItems) {
        if (receivedItems[identifier]) {
          for (const index in receivedItems[identifier]){
            receivedItems[identifier][index]["serviceProjectIdentifier"] = identifier
            items.push(receivedItems[identifier][index])
          }
        }
      }
      return items
    },
    errorResponse(errorMessage) {
      this.message = this.$i18n.t(errorMessage);
      this.messageType = 'error';
      this.$vuetify.goTo(0)
    },
    successResponse(errorMessage) {
      this.message = this.$i18n.t(errorMessage);
      this.messageType = 'success';
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>

</style>
