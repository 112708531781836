<template>
  <div>
    <v-container class="-max-width-limited">
      <v-card :color="'#eee'" class="mt-5">
        <v-container>
          <h2>
            {{ $t('Search') }}
          </h2>
          <v-form @submit.prevent="search" :class="{ 'loading': quickSearchLoading }">
            <v-row>
              <v-col>
                <v-text-field
                    v-model="store.warrantySearchModel.email"
                    :label="i18n.t('E-Mail')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="store.warrantySearchModel.serialnumber"
                    :label="i18n.t('SerialNo')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="store.warrantySearchModel.firstname"
                    :label="i18n.t('Firstname')"
                />
              </v-col>
              <v-col>
                <v-text-field
                    v-model="store.warrantySearchModel.lastname"
                    :label="i18n.t('Lastname')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn block
                       type="submit"
                >
                  {{ $t('Search') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
      <v-snackbar
          v-model="snackbar"
          vertical
          right
          color="warning"
          :location="'top'"
      >
        <div class="text-subtitle-1 pb-2">{{ i18n.t(message) }}</div>
        <template v-slot:action="{ attrs }">
          <v-btn
              color="black"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from 'vue-router/composables'
import i18n from "@/i18n";
import {store} from "@/store";

const message = ref<string|(string | null)[]>('')
const messageType = ref<string|(string | null)[]>('')
const snackbar = ref<boolean>(false)
const quickSearchLoading = ref<boolean>(false)

const router = useRouter()
const route = useRoute()

onMounted(() => {
  if (route.query.message) {
    displayMessage(route.query.message)
    router.replace(router.currentRoute.path)
  }
})
const search = () => {
  router.push({
    name: 'warranty_users',
    // @ts-ignore
    query: store.warrantySearchModel
  })

  quickSearchLoading.value = true
}
const displayMessage = (messageString:string|(string | null)[], type:string = 'warning') => {
  message.value = messageString
  messageType.value = type
  snackbar.value = true
}
</script>
