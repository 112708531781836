<template>
    <v-app>
        <div id="app">
            <HeaderView/>
            <router-view/>
        </div>
    </v-app>
</template>

<script>
    import HeaderView from "./components/HeaderView.vue";

    export default {
        name: 'App',
        components: {
          HeaderView
        }
    }
</script>

<style>
  @import './assets/css/style.css';
</style>
