import Vue from 'vue'
import VueI18n from 'vue-i18n'
// @ts-ignore
import VueCookie from 'vue-cookie'

Vue.use(VueI18n, VueCookie)

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            // @ts-ignore
            messages[locale] = locales(key)
        }
    })
    return messages
}

function getStartingLocale() {
    const browserLanguage = navigator.language.substr(0,2);
    return VueCookie.get('locale') || browserLanguage || 'de'
}

export default new VueI18n({
    locale: getStartingLocale(),
    fallbackLocale: 'en',
    messages: loadLocaleMessages(),
    // silentTranslationWarn: true
})
