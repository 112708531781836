<template>
  <v-container -max-width-limited>
      <v-skeleton-loader
              v-if="!retoure"
              type="image"
      ></v-skeleton-loader>
    <v-alert
        text
        :type="messageType"
        v-if="message"
    >
      {{ message }}
    </v-alert>
    <v-card :color="'#eee'" v-if="retoure" class="mt-5 mb-5">
      <v-container>
        <div class="col-md-12">
          <div class="servicecenter-page" id="servicecenter">
            <div class="bodytext clearfix">
              <h2>{{$t('Information about the service procedure')}}: {{ retoure.returnNumber }}</h2>
              <div class="col-md-12">
                <v-layout row wrap>
                  <v-flex xs6>
                    <v-card>
                      <v-layout>
                        <v-flex xs3>
                          <v-img :src="imageUrl"/>
                        </v-flex>
                        <v-flex>
                          <v-card-title primary-title v-if="retoure.itemInfo">
                            <div>
                              <div class="headline">{{ retoure.itemInfo.itemNo }}</div>
                              <div>{{ retoure.itemInfo.description }}</div>
                              <div>{{ retoure.itemInfo.tpgName }}</div>
                            </div>
                          </v-card-title>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs6>
                    <v-container>
                      <div class="retoure-status">
                        <h3 v-if="retoure.statusText">
                          {{ $t('Current status') }}: {{ $t('retoure.status.' + retoure.statusText) }}
                        </h3>
                        <h3 v-if="retoure.statusCode == 5" class="error">
                          {{ $t('An error has occurred during the transmission of the data') }}
                        </h3>
                        <v-btn
                            v-if="retoure.shipmentLabelExists"
                            @click="sendRetoureLabel(retoure.id)"
                            class="mt-2"
                        >
                          {{ $t('Send return label to customers') }}
                        </v-btn>
                        <a
                            v-if="retoure.shipmentLabelExists"
                            class="mt-2 v-btn v-btn--contained theme--light v-size--default"
                            :download="retoure.returnNumber+'_Label.pdf'"
                            :href="'data:application/octet-stream;base64,'+retoure.shipmentLabel">
                          {{ $t('Download returnlabel') }}
                        </a>
                      </div>
                    </v-container>
                  </v-flex>
                </v-layout>
              </div>
            </div>

            <v-stepper v-model="el" class="retoure_stepper">
              <v-stepper-header>
                <v-stepper-step :complete="retoure.statusCode > 10" step="1">
                  {{ $t('retoure.status.Order registered') }}
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete="retoure.statusCode > 20" step="2">
                  {{ $t('retoure.status.Return arrived') }}
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete="retoure.statusCode >= 49" step="3">
                  {{ $t('retoure.status.In Process') }}
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete="retoure.statusCode >= 49" step="4">
                  {{ $t('retoure.status.Process completed') }}
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
            <div v-if="retoure.trackingUrl">
              <a :href="retoure.trackingUrl" target="_blank">{{ retoure.trackingUrl }}</a>
            </div>
            <v-row>
              <v-col>
                <h2>{{$t('')}}Customer Infos</h2>
                <table style="width:100%">
                  <caption></caption>
                  <thead>
                  <tr>
                    <th scope="default"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{$t('E-Mail')}}</td>
                    <td>{{ retoure.address.email}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Prefix')}}</td>
                    <td>{{ retoure.address.prefix}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Firstname')}}</td>
                    <td>{{ retoure.address.firstName }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Lastname')}}</td>
                    <td>{{ retoure.address.lastName }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Address')}}</td>
                    <td>{{ retoure.address.street }} {{ retoure.address.buildingNo }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Zip')}}</td>
                    <td>{{ retoure.address.cip }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('City')}}</td>
                    <td>{{ retoure.address.city }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Country')}}</td>
                    <td>{{ $t('main.country.' + retoure.address.country.toLowerCase()) }}</td>
                  </tr>
                  <tr>
                    <td>{{$t('Phone')}}</td>
                    <td>{{ retoure.address.telephone }}</td>
                  </tr>
                  </tbody>
                </table>
              </v-col>
              <v-col v-if="Object.keys(retoure.statusHistory).length > 0">
                <h2>Logbook</h2>
                <table style="width:100%">
                  <caption></caption>
                  <thead>
                  <tr>
                    <th scope="default"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th scope="date" class="text-left">{{$t('Date')}}</th>
                    <th scope="event" class="text-left">{{$t('Event')}}</th>
                  </tr>
                  <tr v-for="(history, index) in retoure.statusHistory" :key="index">
                    <td>{{ history.date.split(" ")[0] }}</td>
                    <td> {{ $t('retoure.status.' + history.event) }}</td>
                  </tr>
                  </tbody>
                </table>
              </v-col>K
            </v-row>
            <v-row v-if="retoure.statusInfo">
              <v-col>
                <h2>Statusinfos</h2>
                <table style="width:100%">
                  <tbody>
                  <tr>
                    <th class="text-left">{{$t('Key')}}</th>
                    <th class="text-left">{{$t('Value')}}</th>
                  </tr>
                  <tr v-for="(status, key) in retoure.statusInfo" :key="key" v-show="status !== ''">
                    <td>{{ key }}</td>
                    <td> {{ status }}</td>
                  </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </div>
          <forward-to-support
              :type="'retoure'"
              :subject="retoure.returnNumber"
              :additional_information="additionalInformations"
          />
        </div>
      </v-container>
    </v-card>
    <v-btn
        @click="$router.back()"
        class="back-btn"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t('Back') }}
    </v-btn>
  </v-container>
</template>

<script>
import SearchService from "../../services/SearchService";
import ForwardToSupport from "@/components/base/ForwardToSupport";

export default {
  name: "RetoureDetail",
  components: {
    ForwardToSupport
  },
  data() {
    return {
      el: 0,
      retoure: null,
      imageUrl: '',
      message: '',
      messageType: '',
      additionalInformations: {}
    }
  },
  mounted() {
    const id = this.$route.params.id
    SearchService.getRetoure(id)
        .then(value => {
          this.retoure = value
          this.validateSteps()
          this.loadItemImage()
          this.createAdditionalInformations(value)
        })
        .catch(reason => {
          if (reason['errors'] !== undefined) {
            this.$router.push({
              name: 'search',
              query: { message: 'default_error_message' }
            })
          } else {
            console.log(reason)
          }
        });
  },
  methods: {
    sendRetoureLabel(id) {
      SearchService.sendRetoureLabel(id)
          .then(value => {
            this.successResponse(value.message)
          })
          .catch(reason => {
            this.errorResponse(reason.errors)
          })
    },
    createAdditionalInformations(retoure){
      this.additionalInformations['retoureDate'] = retoure.created;
      this.additionalInformations['retoureState'] = retoure.statusText;
      this.additionalInformations['customer'] = retoure.address.email;
    },
    validateSteps() {
      if (this.retoure.statusCode === 10) {
        this.el = 1
      } else if (this.retoure.statusCode === 20) {
        this.el = 2
      } else if (this.retoure.statusCode > 30 && this.retoure.statusCode < 49) {
        this.el = 3
      }
    },
    loadItemImage() {
      if (this.retoure.itemInfo.assetId) {
        this.imageUrl = 'https://assets.einhell.com/im/imf/800/' + this.retoure.itemInfo.assetId + '/'
      } else {
        this.imageUrl = 'https://assets.einhell.com/im/imf/800/900_347067/'
      }
    },
    errorResponse(errorMessage) {
      this.message = this.$i18n.t(errorMessage);
      this.messageType = 'error';
      this.$vuetify.goTo(0)
    },
    successResponse(errorMessage) {
      this.message = this.$i18n.t(errorMessage);
      this.messageType = 'success';
      this.$vuetify.goTo(0)
    }
  }
}
</script>

<style scoped>

</style>
