<template>
  <v-container -max-width-limited>
    <v-skeleton-loader
            v-if="!order"
            type="image"
    ></v-skeleton-loader>
    <v-alert
        text
        :type="messageType"
        v-if="message"
    >
      {{ message }}
    </v-alert>
    <v-card v-if="order" class="mt-5 mb-5" :color="'#eee'">
      <v-container>
          <v-alert
                  v-if="order.type == 'archive'"
                  type="warning"
                  dismissible
          >{{$t('order.type.archive.message')}}</v-alert>
        <div class="col-md-12 clearfix order-information">
          <div class="entry-edit-head">
            <h2 class="icon-head head-account">
              {{ $t('Order') }} <span v-if="order.externalOrderId">#{{ order.externalOrderId }}</span>
            </h2>
          </div>
          <v-row>
            <v-col>
              <!--Order Information-->
              <div class="entry-edit">
                <div class="entry-edit-head">
                  <h4 class="icon-head head-account">{{ $t('Order information') }}</h4>
                </div>
                <div class="fieldset">
                  <table>
                    <caption></caption>
                    <thead>
                    <tr>
                      <th id="default"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="order.orderDate">
                      <td>
                        <label>{{ $t('Order date') }}: </label>
                      </td>
                      <td class="value">
                        <strong>{{ formatDate(order.orderDate) }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{ $t('Order status') }}: </label>
                      </td>
                      <td class="value">
                        <strong><span
                            id="order_status">{{ $t('order.status.' + order.orderState) }}</span></strong>
                      </td>
                    </tr>
                    <tr v-if="order.shopOrderSystem">
                      <td>
                        <label>{{ $t('Ordered in') }} </label>
                      </td>
                      <td class="value">
                        <strong v-if="order.shopOrderSystemName">
                          {{ order.shopOrderSystemName }}
                        </strong>
                        <strong v-else>
                          {{ $t('channel.code.' + order.shopOrderSystem) }}
                        </strong>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </v-col>
            <v-col>
              <!--Account Information-->
              <div class="entry-edit">
                <div class="entry-edit-head">
                  <h4 class="icon-head head-account">{{ $t('User Account Information') }}</h4>
                </div>
                <div class="fieldset">
                  <div class="hor-scroll">
                    <table class="form-list" v-if="order">
                      <caption></caption>
                      <thead>
                      <tr>
                        <th id="default"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <label>{{ $t('E-Mail') }}: </label>
                        </td>
                        <td class="value">
                          <strong>{{ order.userEmail }}</strong>
                        </td>
                      </tr>
                      <tr v-if="order.billingAddress.ustId">
                        <td><label>{{ $t('Tax/VAT Number') }}: </label></td>
                        <td class="value">
                          <strong>{{ order.billingAddress.ustId }}</strong>
                        </td>
                      </tr>
                      <tr v-if="order.customer">
                        <td>
                          <label>{{ $t('Customer group') }}: </label>
                        </td>
                        <td class="value">
                          <strong v-if="!order.customer.group">{{ $t('Guest') }}</strong>
                          <strong v-else>{{ order.customer.group.name }}</strong>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="col-md-12 clearfix adresses">
          <v-row>
            <v-col v-if="order.billingAddress">
              <!--Billing Address-->
              <div class="entry-edit-head">
                <h4 class="icon-head head-billing-address">{{ $t('Billing address') }}</h4>
              </div>
              <fieldset>
                <legend></legend>
                <address style="background-color:#fff">
                  {{ order.billingAddress.firstname }} {{ order.billingAddress.lastname }}<br>
                  {{ order.billingAddress.street1 }} {{ order.billingAddress.street2 }}<br>
                  {{ order.billingAddress.postalCode }} {{ order.billingAddress.city }}<br>
                  <span v-if="order.billingAddress.telephone">{{ order.billingAddress.telephone }}<br></span>
                  <span v-if="order.billingAddress.companyName">{{ order.billingAddress.companyName }}<br></span>
                  <span v-if="order.billingAddress.ustId">{{ order.billingAddress.ustId }}<br></span>
                  {{ $t('main.country.' + order.billingAddress.country.toLowerCase()) }}<br>
                </address>
              </fieldset>
            </v-col>
            <v-col v-if="order.shippingAddress">
              <!--Shipping Address-->
              <div class="entry-edit">
                <div class="entry-edit-head">
                  <h4 class="icon-head head-shipping-address">{{ $t('Shipping address') }}</h4>
                  <fieldset>
                    <legend></legend>
                    <address style="background-color:#fff">
                      {{ order.shippingAddress.firstname }} {{ order.shippingAddress.lastname }}<br>
                      {{ order.shippingAddress.street1 }} {{ order.shippingAddress.street2 }}<br>
                      {{ order.shippingAddress.postalCode }} {{ order.shippingAddress.city }}<br>
                      <span v-if="order.shippingAddress.telephone">{{ order.shippingAddress.telephone }}<br></span>
                      <span v-if="order.shippingAddress.companyName">{{ order.shippingAddress.companyName }}<br></span>
                      <span v-if="order.shippingAddress.ustId">{{ order.shippingAddress.ustId }}<br></span>
                      {{ $t('main.country.' + order.shippingAddress.country.toLowerCase()) }}<br>
                    </address>
                  </fieldset>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="col-md-12 clearfix shipping-infos" v-if="order.shipment">
          <h4 class="icon-head head-billing-address">{{ $t('Shipping Infos') }}</h4>
            <span v-if="order.shipment.method">
              {{ $t("order.shipping.method." + order.shipment.method) }}
            </span>
            <span v-if="order.shipment.state">({{ $t("order.shipping.status." + order.shipment.state) }})</span>
            <template v-if="order.shipment.trackingUrl">
              <div v-for="(url, index) in order.shipment.trackingUrl.split('<br/>')" :key="index">
                <a v-if="url !=='noshipment_content'" target="_blank" :href="url">{{ url }}</a>
              </div>
            </template>
        </div>
        <div class="col-md-12 clearfix shipping-infos" v-else>
          {{ $t('No shipping information available') }}
        </div>
        <div class="col-md-12 clearfix order-totals">
          <v-row>
            <v-col v-if="order.total > 0 || order.orderTotalPaid > 0">
              <h4>{{ $t('Order totals') }}</h4>
              <table>
                <caption></caption>
                <thead>
                <tr>
                  <th id="default"></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ $t('Subtotal') }}:</td>
                  <td><span class="price">{{ formatMoney(order.itemsTotalGrossWithoutDiscount) }}</span></td>
                </tr>
                <tr>
                  <td>{{ $t('Shipping costs') }}:</td>
                  <td><span class="price">{{ formatMoney(order.shippingGrossWithDiscount) }}</span></td>
                </tr>
                <tr v-if="order.discountTotalOnGross">
                  <td>{{ $t('Discount Total') }}:</td>
                  <td>
                    <span class="price">
                      <template v-if="order.discountTotalOnGross > 0">-</template>{{ formatMoney(order.discountTotalOnGross) }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('Value added tax') }}:</td>
                  <td><span class="price">{{ formatMoney(order.orderTotalTaxWithDiscount) }}</span></td>
                </tr>
                <tr>
                  <td>{{ $t('Total') }}:</td>
                  <td><span class="price">{{ formatMoney(order.orderTotalPaid) }}</span></td>
                </tr>
                <tr>
                  <td>{{ $t('Currency') }}:</td>
                  <td>{{ order.currency }}</td>
                </tr>
                </tbody>
              </table>
            </v-col>
            <v-col v-if="order.statusHistory">
              <h4>{{ $t('Information on order status') }}</h4>
              <ul class="note-list">
                <li v-for="(status,index) in order.statusHistory" :key="index">
                  <strong>{{ formatDate(status.createdAt) }}</strong>
                  <span class="separator"> | {{ status.status }}</span><br>
                  <small>{{ status.message }}</small><br>
                </li>
              </ul>
            </v-col>
          </v-row>
        </div>
        <div class="col-md-12 clearfix order-items" v-if="items.length > 0">
          <h4>{{ $t('Ordered articles') }}</h4>
          <v-data-table
              :headers="headers"
              :items="items"
              :hide-default-footer="true"
              class="elevation-1"
          >
          </v-data-table>
        </div>
        <div class="col-md-12 clearfix payment-method" v-if="order.payment">
          <h4>{{ $t('Transaction Infos') }}</h4>
          {{ $t('Payment') }}:
          <span v-if="order.payment.method">{{ $t("order.payment.method." + order.payment.method) }}</span>&nbsp;
          <span v-if="order.payment.state">({{ $t("order.payment.status." + order.payment.state) }})</span>
        </div>
        <div class="col-md-12 clearfix payment-method" v-if="order.paymentType">
          <h4>{{ $t('Transaction Infos') }}</h4>
          {{ $t('Payment') }}:
          <div>
            {{ $t("order.payment.method." + order.paymentType) }}
          </div>
        </div>
        <div class="col-md-12 clearfix cic-support">
          <forward-to-support
              :type="'order'"
              :subject="order.number"
              :additional_information="additionalInformations"
          />
        </div>
      </v-container>
    </v-card>
    <v-btn
        @click="$router.back()"
        class="back-btn"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t('Back') }}
    </v-btn>
  </v-container>
</template>

<script>
import SearchService from "../../services/SearchService";
import {formatDate, formatMoney} from "@/services/globalFunctions";
import ForwardToSupport from "@/components/base/ForwardToSupport";

export default {
  name: "OrderDetail",
  components: {
    ForwardToSupport
  },
  data() {
    return {
      headers: [],
      order: null,
      user: null,
      items: [],
      message: '',
      messageType: '',
      additionalInformations: {}
    }
  },
  mounted() {
    this.headers = [
      {text: this.$i18n.t('Productdescription'), value: 'description', sortable: false},
      {text: this.$i18n.t('Articlenumber'), value: 'itemNo', sortable: false},
      {text: this.$i18n.t('Product Unit Price'), value: 'unitGrossWithoutDiscount', sortable: false},
      {text: this.$i18n.t('Quantity'), value: 'quantity', sortable: false},
        {text: this.$i18n.t('Product Line Price'), value: 'lineGrossWithoutDiscount', sortable: false},
        {text: this.$i18n.t('Product Line Discount'), value: 'lineDiscountOnGross', sortable: false},
        {text: this.$i18n.t('Product Line Price With Discount'), value: 'lineGrossWithDiscount', sortable: false}
    ];
    SearchService.getOrder(this.$route.params.id)
        .then(value => {
          this.order = value
          this.user = value.keycloakUser
          this.createItemList(this.order)
          this.createAdditionalInformations(value)
          this.sortStatusHistory(this.order)
        })
        .catch(reason => {
          if (reason['errors'] !== undefined) {
            this.$router.push({
              name: 'search',
              query: {message: 'default_error_message'}
            })
          } else {
            console.log(reason)
          }
        });
  },
  methods: {
    createItemList(order) {
      const itemList = order.items;
      const items = []
      if (itemList) {
        itemList.forEach(function (item) {
          items.push({
              description: item.description,
              itemNo: item.itemNo,
              unitGrossWithoutDiscount: formatMoney(item.unitGrossWithoutDiscount, order.currency),
              quantity: item.quantity,
              lineGrossWithoutDiscount: formatMoney(item.lineDiscountOnGross +item.lineGrossWithDiscount, order.currency),
              lineDiscountOnGross: formatMoney(- item.lineDiscountOnGross, order.currency),
              lineGrossWithDiscount: formatMoney(item.lineGrossWithDiscount, order.currency)
          });
        })
      }
      this.items = items
    },
    createAdditionalInformations(order) {
      this.additionalInformations['orderDate'] = this.formatDate(order.orderDate);
      this.additionalInformations['channel'] = order.shopOrderSystem;
      this.additionalInformations['customer'] = order.userEmail;
    },
    sortStatusHistory(order) {
        order.statusHistory.sort(function(a, b) {
            var keyA = new Date(a.createdAt),
                keyB = new Date(b.createdAt);
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
        });
    },
    showCustomer(email) {
      this.$router.push({
        name: 'user_list',
        params: {email: email},
      })
    },
    formatMoney(total) {
      return formatMoney(total, this.order.currency);
    },
    formatDate(dateString) {
      return formatDate(dateString);
    },
  }
}
</script>

<style scoped>

</style>
