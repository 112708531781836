function fillUpWithZeros(number:number) {
    return number.toString().padStart(2, '0')
}

function formatDate(dateString:string) {
    const date = new Date(dateString)
    return fillUpWithZeros(date.getUTCDate()) + '.' + fillUpWithZeros((date.getUTCMonth() + 1)) + '.' + date.getUTCFullYear() +
        ' ' + fillUpWithZeros(date.getUTCHours()) + ':' + fillUpWithZeros(date.getUTCMinutes()) + ':' + fillUpWithZeros(date.getUTCSeconds());
}

function convertTimestamp(timestamp:number) {
    const date = new Date(timestamp)
    return fillUpWithZeros(date.getUTCDate()) + '.' + fillUpWithZeros((date.getUTCMonth() + 1)) + '.' + date.getUTCFullYear() +
        ' ' + fillUpWithZeros(date.getHours()) + ':' + fillUpWithZeros(date.getUTCMinutes()) + ':' + fillUpWithZeros(date.getUTCSeconds());
}

function formatMoney(total:number, currencyCode:string) {
    const currenyCodes = {
        'EUR': '€',
        'GBP': '£'
    };

    // @ts-ignore
    let currency = currenyCodes[currencyCode];
    if (!currency) {
        currency = currencyCode
    }

    return total.toFixed(2) + ' ' + currency;
}

export {fillUpWithZeros, formatDate, convertTimestamp, formatMoney}
