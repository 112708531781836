<template>
  <div>
    <v-container class="-max-width-limited">
      <v-card :color="'#eee'" class="mt-5">
        <v-container>
          <h2>
            {{ $t('Quicksearch') }}
          </h2>
          <v-form @submit.prevent="quickNumberSearch" :class="{ 'loading': quickSearchLoading }">
            <v-row>
              <v-col>
                <v-text-field
                    v-model="store.cicSearchModel.number"
                    :placeholder="$t('Enter ordernumber or retourenumber')"
                />
              </v-col>
              <v-col>
                <v-btn block
                       type="submit"
                >
                  {{ $t('Search') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
      <v-card :color="'#eee'" class="mt-5">
        <v-container>
          <h2>
            {{ $t('Customersearch') }}
          </h2>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="store.cicSearchModel.email"
                  :label="$t('E-Mail')"
                  required
              />
            </v-col>
            <v-col>
              <v-btn v-for="type in searchTypes" :key="type.value"
                     @click="customerSearch(type.value)"
                     class="ma-1"
              >
                {{ type.label }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <v-snackbar
        v-model="snackbar"
        vertical
        right
        :color="messageType"
        :location="'top'"
    >
      <div class="text-subtitle-1 pb-2">{{ i18n.t(message) }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn
            color="black"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          {{ i18n.t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script setup lang="ts">
import SearchService from '../services/SearchService'
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from 'vue-router/composables'
import i18n from "@/i18n";
import {TranslateResult} from "vue-i18n";
import {store} from "@/store";

const message = ref<string|(string | null)[]>('')
const messageType = ref<string|(string | null)[]>('')
const snackbar = ref<boolean>(false)
const searchType = ref<{ label:TranslateResult, value:string }|null>(null)
const searchTypes = ref<{ label:TranslateResult, value:string }[]>([])
const quickSearchLoading = ref<boolean>(false)

const router = useRouter()
const route = useRoute()


onMounted(() => {
  searchTypes.value = [
    {label: i18n.t('Order'), value: 'order'},
    {label: i18n.t('Retoure'), value: 'retoure'},
    {label: i18n.t('Customer Complaint'), value: 'customercomplaint'},
    {label: i18n.t('Roboservice'), value: 'roboservice'},
    {label: i18n.t('MyEinhell'), value: 'myeinhell'},
  ];
  searchType.value = {label: i18n.t('Order'), value: 'order'};
  if (route.query.message) {
    displayMessage(route.query.message)
    router.replace(router.currentRoute.path)
  }
})
const quickNumberSearch = () => {
  quickSearchLoading.value = true
  SearchService.quickSearch(store.cicSearchModel.number.trim())
      .then(value => {
        store.cicSearchModel.email = ''
        if (value.order.id) {
          router.push({
            name: 'order_detail',
            params: {id: value.order.id},
          })
        }
        if (value.retoure.id) {
          router.push({
            name: 'retoure_detail',
            params: {id: value.retoure.id},
          })
        }
        if (value.customercomplaint.id) {
          router.push({
            name: 'customer_complaint_detail',
            params: {id: value.customercomplaint.id},
          })
        }
        if (value.roboService.id) {
          router.push({
            name: 'roboservice_detail',
            params: {id: value.roboService.id},
          })
        }
        displayMessage('No hits found')
        quickSearchLoading.value = false
      })
      .catch(reason => {
        quickSearchLoading.value = false
        if (reason['errors'] !== undefined) {
          displayMessage(reason['errors'])
        } else {
          console.log(reason)
        }

      });
}
const customerSearch = (type:string) => {
  if (store.cicSearchModel.email) {
    store.cicSearchModel.number = ''
    switch (type) {
      case "order":
        router.push({
          name: 'order_list',
          query: {email: store.cicSearchModel.email}
        })
        break;
      case "retoure":
        router.push({
          name: 'retoure_list',
          query: {email: store.cicSearchModel.email}
        })
        break;
      case "customercomplaint":
        router.push({
          name: 'customer_complaint_list',
          query: {email: store.cicSearchModel.email}
        })
        break;
      case "roboservice":
        router.push({
          name: 'roboservice_list',
          query: {email: store.cicSearchModel.email}
        })
        break;
      case "myeinhell":
        router.push({
          name: 'user_list',
          params: {email: store.cicSearchModel.email},
        })
        break;
    }
  }
}
const displayMessage = (messageString:string|(string | null)[], type = 'warning') => {
  message.value = messageString
  messageType.value = type
  snackbar.value = true
}
</script>
