<template>
  <v-container -max-width-limited>
    <v-skeleton-loader
            v-if="userList.length == 0"
            type="image"
    ></v-skeleton-loader>
    <v-card :color="'#eee'" class="mt-5 mb-5" v-for="user in userList" :key="user.id">
      <v-container>
        <div class="col-md-12">
          <strong>{{ user.email }}</strong>
        </div>
        <div class="col-md-12">
          <span>{{ user.firstname }}</span> <span>{{ user.lastname }}</span>,
          <span>{{user.country.toUpperCase()}}-{{user.zip}} {{user.city}}</span>,
          <span>{{user.street}}</span>
        </div>
        <div class="col-md-12">
          <v-btn
              @click="showDetails(user.id)"
          >
            {{ i18n.t('Show Details') }}
          </v-btn>
        </div>
      </v-container>
    </v-card>
    <v-btn
        @click="$router.back()"
        class="back-btn"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ i18n.t('Back') }}
    </v-btn>
  </v-container>
</template>

<script setup lang="ts">
import {WarrantyService} from '../../../services/WarrantyService'
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from 'vue-router/composables'
import i18n from "@/i18n";
import {WarrantySearchModelType} from "@/types/WarrantySearchModelType";

const router = useRouter()
const route = useRoute()

const userList = ref([])

onMounted(() => {
  // @ts-ignore
  const searchModel: WarrantySearchModelType = route.query
  WarrantyService.search(searchModel)
      .then(value => {
        if (value.length == 0) {
          router.push({
            name: 'warranty',
            query: { message: 'No hits found' }
          })
        }
        if (value.length == 1) {
          showDetails(value[0].id)
        }
        if (value.length > 1) {
          userList.value = value
        }
      })
      .catch(reason => {
        if (reason['errors'] !== undefined) {
          router.push({
            name: 'warranty',
            query: { message: reason['errors'] }
          })
        } else {
          console.log(reason)
          router.push({
            name: 'warranty',
            query: { message: 'default_error_message' }
          })
        }
      });
})

function showDetails(id: string) {
  router.push({
    name: 'warranty_user_detail',
    params: {id: id}
  })
}
</script>
