<template>
    <v-container -max-width-limited>
        <v-card v-if="user" class="mt-5 mb-5" :color="'#eee'">
            <v-container>
                <div class="col-md-12 clearfix order-information">
                    <v-row>
                        <v-col>
                            <!--Account Information-->
                            <div class="entry-edit">
                                <div class="entry-edit-head">
                                    <h2 class="icon-head head-account">{{ i18n.t('Create User') }}</h2>
                                </div>
                                <div class="fieldset">
                                    <div class="hor-scroll">
                                        <table class="form-list" v-if="user">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <label>{{ i18n.t('E-Mail') }} *: </label>
                                                </td>
                                                <td class="value">
                                                    <v-text-field outlined dense hide-details :error="validation.email"
                                                                  v-model="user.email"
                                                                @change="user.email = removeWhiteSpace(user.email)"></v-text-field>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>{{ i18n.t('Firstname') }} *: </label>
                                                </td>
                                                <td class="value">
                                                    <v-text-field outlined dense hide-details :error="validation.firstname"
                                                                  v-model="user.firstname"
                                                                  @change="user.firstname = removeWhiteSpace(user.firstname)"></v-text-field>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>{{ i18n.t('Lastname') }} *: </label>
                                                </td>
                                                <td class="value">
                                                    <v-text-field outlined dense hide-details :error="validation.lastname"
                                                                  v-model="user.lastname"
                                                                  @change="user.lastname = removeWhiteSpace(user.lastname)"></v-text-field>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>{{ i18n.t('Zip') }} *: </label>
                                                </td>
                                                <td class="value">
                                                    <v-text-field outlined dense hide-details :error="validation.zip"
                                                                  v-model="user.zip"
                                                                  @change="user.zip = removeWhiteSpace(user.zip)"></v-text-field>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>{{ i18n.t('City') }} *: </label>
                                                </td>
                                                <td class="value">
                                                    <v-text-field outlined dense hide-details :error="validation.city"
                                                                  v-model="user.city"
                                                                  @change="user.city = removeWhiteSpace(user.city)"></v-text-field>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>{{ i18n.t('Street') }} *: </label>
                                                </td>
                                                <td class="value">
                                                    <v-text-field outlined dense hide-details :error="validation.street"
                                                                  v-model="user.street"
                                                                  @change="user.street = removeWhiteSpace(user.street)"></v-text-field>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>{{ i18n.t('Country') }} *: </label>
                                                </td>
                                                <td class="value">
                                                    <v-select outlined dense hide-details :error="validation.country"
                                                              :items="store.warranty.availableCountries"
                                                              @change="selectCountry"
                                                              v-model="user.country"></v-select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>{{ i18n.t('Phone') }}: </label>
                                                </td>
                                                <td class="value">
                                                    <v-text-field outlined dense hide-details
                                                                  v-model="user.phone"
                                                                  @change="user.phone = removeWhiteSpace(user.phone)"></v-text-field>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>{{ i18n.t('Locale') }} *: </label>
                                                </td>
                                                <td class="value">
                                                    <v-select outlined dense hide-details :error="validation.locale"
                                                              :items="store.warranty.availableLocales"
                                                                  v-model="user.locale"></v-select>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <v-btn color="green" @click="saveUser">{{ i18n.t('Save') }}</v-btn>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </v-card>
        <v-snackbar
                v-model="snackbar"
                vertical
                right
                :color="messageType"
                :location="'top'"
        >
            <div class="text-subtitle-1 pb-2">{{ i18n.t(message) }}</div>
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="black"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    {{ i18n.t('Close') }}
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script setup lang="ts">
import {WarrantyService} from '../../../services/WarrantyService'
import {onMounted, ref} from "vue";
import {useRouter} from 'vue-router/composables'
import i18n from "@/i18n";
import {WarrantyUserType} from "@/types/WarrantyUserType";
import {store} from "@/store";
import {WarrantyLocationInfoService} from "@/services/WarrantyLocationInfoService";

const router = useRouter()
const message = ref<string | (string | null)[]>('')
const messageType = ref<string | (string | null)[]>('')
const snackbar = ref<boolean>(false)

const user = ref<WarrantyUserType | any>({})

const validation = ref({
    firstname: false,
    lastname: false,
    email: false,
    zip: false,
    city: false,
    street: false,
    country: false,
    phone: false,
    locale: false,
})

onMounted(() => {
    WarrantyLocationInfoService.getCountries()
})

function selectCountry(country:string) {
    WarrantyLocationInfoService.getLocales(country)
}

function removeWhiteSpace(text:string) {
  return text.trim();
}

function saveUser() {
    message.value = ''
    if (validataUser(user.value)) {
        WarrantyService.createUser(user.value)
            .then(value => {
                router.push({
                    name: 'warranty_user_detail',
                    params: {id: value}
                })
            })
            .catch(reason => {
                if (reason['msg'] !== undefined) {
                    displayMessage(reason['msg'])
                } else {
                    console.log(reason)
                    displayMessage('Some inputs are invalid', 'error')
                }
            });
    }
}

function validataUser(user: WarrantyUserType): boolean {
    validation.value.email = false
    validation.value.firstname = false
    validation.value.lastname = false
    validation.value.zip = false
    validation.value.city = false
    validation.value.street = false
    validation.value.country = false
    validation.value.locale = false
    console.log(user.firstname)
    let valid = true
    if (!user.email) {
        validation.value.email = true
        valid = false
    }
    if (!user.firstname) {
        validation.value.firstname = true
        valid = false
    }
    if (!user.lastname) {
        validation.value.lastname = true
        valid = false
    }
    if (!user.zip) {
        validation.value.zip = true
        valid = false
    }
    if (!user.city) {
        validation.value.city = true
        valid = false
    }
    if (!user.street) {
        validation.value.street = true
        valid = false
    }
    if (!user.country) {
        validation.value.country = true
        valid = false
    }
    if (!user.locale) {
        validation.value.locale = true
        valid = false
    }

    return valid
}

const displayMessage = (messageString: string | (string | null)[], type: string = "warning") => {
    message.value = messageString
    messageType.value = type
    snackbar.value = true
}
</script>
